import * as copy from 'copy-to-clipboard';
import InformationModalDialog from '../../../../components/others/modal/InformationModalDialog';
import PostbackUrlContent from '../postbackUrl/contents/PostbackUrlContent';
import React from 'react';
import styles from './styles/PostbackSettingsForm.module.scss';
import { Button } from 'antd';
import { displayErrorMessage } from '../../../../helpers/MessageHelper';
import { IPostback } from '../interfaces/IPostbackSettingsState';
import { IPostbackSettingsFormProps } from './interfaces/IPostbackSettingsFormProps';
import { resources } from '../../../../common/Resources';
import { ConfirmForm } from '../../../../components/confirmForm/ConfirmForm';

export class PostbackSettingsForm extends React.Component<IPostbackSettingsFormProps> {

    renderDeleteButton = (urlId: number) => {
        return (
            <Button
                className={styles.controlButton}
                icon='delete'
                onClick={() => this.props.onDelete && this.props.onDelete(urlId).catch(errMsg => displayErrorMessage(errMsg))}>
            </Button>
        );
    }

    renderLabel = (postbackType: string): string => {
        switch (postbackType) {
            case 'postback_awaiting':
                return resources.offersDetails.urlForHolds;
            case 'postback_double':
                return resources.offersDetails.urlForDuplicated;
            case 'postback_rejected':
                return resources.offersDetails.urlForRejects;
            case 'postback_sold':
                return resources.offersDetails.urlForSales;
            case 'postback_trash':
                return resources.offersDetails.urlForTrash;
            case 'postback_excluded':
                return resources.offersDetails.urlForExcluded;
            case 'postback_tocheck':
                return resources.offersDetails.urlForCheck;
            default:
                return 'Unknown status'
        }
    }

    public render() {
        const { urls, type } = this.props.postbackConfig;
        const { statuses, onUpsert } = this.props;
        return (
            <div className={`${styles.formItem__outsideForm}`}>
                <div className={styles.label}>{this.renderLabel(type)}</div>
                {urls.map((postback: IPostback) => (
                    <div className={styles.fakeInput} key={postback.id}>
                        <p>{postback.value}</p>
                        <div className={styles.controlsContainer}>
                            <InformationModalDialog
                                buttonTooltip={resources.offersDetails.updatePostback}
                                icon='edit'
                                buttonClassName={styles.controlButton}
                                withHideMethod={true}
                                width={692}
                            >
                                <PostbackUrlContent
                                    postbackUrl={postback.value}
                                    postbackId={postback.id}
                                    canStatusChange={false}
                                    statuses={statuses}
                                    status={type}
                                    onUpsert={onUpsert}
                                    customVariables={this.props.customVariables}
                                />
                            </InformationModalDialog>
                            <Button
                                className={styles.controlButton}
                                icon='copy'
                                onClick={() => copy.default(postback.value !== '' ? postback.value : ' ')}
                            ></Button>
                            <InformationModalDialog
                                buttonTooltip={resources.offersDetails.clearPostback}
                                icon='close'
                                buttonClassName={styles.controlButton}
                                withHideMethod={true}
                                width={400}
                            >
                                <ConfirmForm
                                    title={resources.offersDetails.clearPostback}
                                    onConfirm={()=> {
                                        return new Promise(async (resolve, reject) => {
                                            try {
                                                await onUpsert('', type, postback.id);
                                                resolve();
                                            }
                                            catch (err){
                                                reject(err.response.data.message);
                                            }
                                    })}}
                                />
                            </InformationModalDialog>
                            {this.props.onDelete && this.renderDeleteButton(postback.id)}
                        </div>
                    </div>
                ))}
            </div>
        );
    };
}
