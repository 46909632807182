import LocalizedStrings from "react-localization";

export const _onSetLanguage = (lang: string) => {
  resources.setLanguage(lang);
};

export const resources = new LocalizedStrings({
  en_GB: {
    validation: {
      current_password_is_incorrect: "Current password is incorrect",
      // Name
      nameIsRequired: "Name is required",
      // Last name
      lastNameIsRequired: "Last name is required",
      // E-mail
      emailIsRequired: "E-mail is required",
      incorrectEmailAdres: "Incorrect e-mail adress",
      requiredUtf8Characters: "Required utf-8 characters",
      // Password
      passwordIsRequired: "Password is required",
      confirmPasswordIsRequired: "Confirm password is required",
      requiredTheSamePasswords: "Required the same passwords",
      passwordCondition:
        "Password must contain at least 1 upper case letter, 1 lower case letter, 1 numeric character, at least 2 special characters and must contain at least 8 characters and max 32 characters.",
      // SkypeId
      skypeIdIsRequired: "Skype ID is required",
      // Telegram
      telegramIsRequired: "Telegram is required",
      // Country
      countryIsRequired: "Country is required",
      // Rest
      requiredMin3Characters: "Required min 3 characters",
      requiredMin8Characters: "Required min 8 characters",
      requiredMax15Characters: "Required max 15 characters",
      requiredMax20Characters: "Required max 20 characters",
      requiredMax30Characters: "Required max 30 characters",
      requiredMax50Characters: "Required max 50 characters",
      requiredMax191Characters: "Required max 191 characters",
      requiredMax250Characters: "Required max 250 characters",
      requiredMax255Characters: "Required max 255 characters",
      requiredAlphaNumeric: "Required alphaNumeric",
      statusIsRequired: "Status is required (number)",
      requiredMax11Numbers: "Required max 11 numbers",
      required: "Field is required",
      invalidDomain: "Domain invalid",
      duplicatedParam: "Duplicated parameter name",
      invalidParamName: "Invalid parameter name",
      invalidParamValue: "Invalid parameter value",
      invalidUrl: "Invalid URL",
      customInvalidUrlError:
        "IMPORTANT: This URL seems invalid but you can still save it.",
    },
    statistics: {
      to_check: 'To Check',
      toCheckDescription: 'Leads which were marked as Doubles for the last 30 days.',
      callCenterTitle: "Call center classifiers",
      totalDescription:
        "Validated, Send to Call Center, Awaiting, Rejected, Excluded and Sold",
      awaitingDescription:
        "Classification pending (called leads by Call Center)",
      rejectedDescription: "Called but rejected",
      soldDescription: "Verified sales",
      orderCreatedDescription: "Awaiting verification",
      trashDescription: "Wrong numbers etc.",
      excludedDescription: "Doubles and not contacted",
      validatedDescription:
        "Validated by system but not sent to call center yet",
      sendToCcDescription: "Sent to call center but not called yet",
      doubledDescription: "Marked by system",
      verifiedApprovrdRateDescription: "((Order Created + Sold) / Total) * 100",
      approvedRateDescription: "(Sold / Total) * 100",
      leads: "Leads",
      total: "Total",
      awaiting: "Awaiting",
      rejected: "Rejected",
      approved: "Approved",
      tocheck: "To Check",
      approveRate: "Approve rate",
      payoutAmount: "Payout amount",
      sold: "Sold",
      orderCreated: "Order Created",
      trash: "Trash",
      excluded: "Excluded",
      validated: "Validated",
      sendToCC: "Send to Call Center",
      sentcc: "Sent to CC",
      double: "Double",
      verifiedApprovrdRate: "Approve Rate (Potential)",
      approvedRate: "Verified Approve Rate",
      unrecognized: "Unrecognized",
      noMoney: "No money",
      noMoneyDescription: "No money Description",
      awaitingAll: "Awaiting all",
      awaitingAllDescription: "New lead, Validated, Awaiting, Send to CC",
      title: "Statistics leads",
      summary: "Summary statistics",
      numberOfLeadsByOffer: "Number of leads by offer",
      percentageOfApprovedLeads: "Percentage of approved leads",
    },
    tabPane: {
      details: "Details",
      summary: "Summary",
      personalData: "Personal data",
      changePassword: "Change password",
      notifications: "Notifications",
      apiToken: "API Token",
      globalPostbacks: "Global postbacks",
      registrationLink: "Registration link",
    },
    buttons: {
      upload: "Upload",
      yes: "Yes",
      no: "No",
      confirm: "Confirm",
      today: "Today",
      yesterday: "Yesterday",
      thisMonth: "This month",
      lastMonth: "Last month",
      last30Days: "Last 30 days",
      resetFilters: "Clear filters",
      addFilter: "Add filter",
      removeFilter: "Remove filter",
      clearAll: "Clear all",
      submit: "Submit",
      search: "Search",
      cancel: "Cancel",
      refreshTable: "Refresh table",
      updateCampaign: "Update campaign",
      reset: "Reset",
      register: "Register",
      create: "Create",
      login: "Login",
      save: "Save",
      save_new_password: "Save new password",
      send: "Send",
      apply: "Apply",
      generateToken: "Generate token",
      addNewEmployee: "Add new employee",
      addNewProduct: "Add new product",
      add_new: "Add new",
      filter: "Filter",
      addVariable: "Add variable",
      goToMainPage: "Go to main page",
      download: "Download",
      exportXlsx: "Export XLSX",
      downloading: "Downloading",
      new: "New",
      switchToUser: "Switch To",
      backToAdminAccount: "Back To Admin",
      setPostbackForAllProducts: "Set this postbacks for all products",
      show: "Show",
      hide: "Hide",
      enable: "Enable",
      disable: "Disable",
    },
    radioButtons: {
      leadsCreatedDate: "Lead created at date",
      leadsApprovedDate: "Lead approved at date",
      leadsSoldDate: "Lead sold at date",
      leadsCallCenterLastCallDate: "Lead call center last call date",
      salesCountryTimezone: "Sales country trimezone",
      myTimezone: "My timezone",
    },
    labels: {
      visibility: "Visibility",
      active_products: "Active products",
      inactive_products: "Inactive products",
      all_products: "All products",
      are_you_sure: "Are you sure?",
      do_you_want_to_generate_this_campaign:
        "Do you want to generate this campaign?",
      create_leads_on_order_delivered_status_global:
        "Create leads on order delivered status ( GLOBAL )",
      partner_token: "Partner token",
      warnings: "Warnings",
      fails: "Fails",
      success: "Successes",
      comment: "Comment",
      selected: "Selected",
      items: "items",
      global_actions: "Global actions",
      partners_that_registerd_from_your_ref_link:
        "Partners that registerd from your ref link: ",
      beauty: "Beauty",
      health: "Health",
      slimming: "Slimming",
      adult: "Adult",
      other: "Other",
      search: "Search",
      latest_news: "Latest News",
      top_offers: "Top offers",
      found: "Found",
      rows: "rows",
      balance: "Balance",
      hold: "Hold",
      your_manager: "Your manager",
      no_manager: "No manager",
      profile: "Profile",
      log_out: "Log Out",
      reportByTimezone: "Report by time zone",
      dateType: "Date type",
      dateRange: "Date range",
      dateFrom: "Date from",
      dateTo: "Date to",
      setQuickDate: "Set quick date",
      selectedFilters: "Selected filters",
      status: "Status",
      country: "Country",
      product: "Product",
      lead_type: "Lead type",
      partner: "Partner",
      userEmail: "User e-mail",
      payoutScheme: "Payout scheme",
      remarketingCampaign: "Remarketing campaign",
      classifierName: "Classifier name",
      accountManager: "Account manager",
      selectTimeZone: "Select time zone ",
      timezone: "Time zone",
      isActive: "Is active ",
      isExcluded: "Excluded from double",
      isOnline: "Is online ",
      type: "Type",
      name: "Name",
      domain: "Domain",
      user: "User",
      password: "Password",
      repeatPassword: "Repeat password",
      baseUrl: "Base URL",
      id: "ID",
      email: "E-mail",
      skype: "Skype",
      telegram: "Telegram",
      object: "Object",
      message: "Message",
      section: "Section",
      phone: "Phone number",
      currentPassword: "Current password",
      newPassword: "New password",
      repeatNewPassword: "Repeat new password",
      apiToken: "API Token",
      click_id: "click_id",
      pub_id: "Pub ID",
      lp_url: "Lp URL",
      second_name: "Second name",
      short_name: "Short name",
      role: "Role",
      description: "Description",
      application_access: "Application access",
      pages: "Pages",
      products: "Products",
      addressLine1: "Adress line 1",
      addressLine2: "Adress line 2",
      city: "City",
      state: "State",
      zipCode: "ZIP code",
      isInternal: "Internal",
      partnersAwaitingActivation: "Partners awaiting activation",
      allPartners: "All Partners",
      company: "Company",
      basedOnRole: "Based on role",
      campaigns: "Campaigns",
      instances: "Instances",
      currency: "Currency",
      code: "Code",
      address: "Address",
      operative: "Operative",
      name_eng: "Name (eng)",
      bank_transfer: "Bank transfer",
      district: "District",
      subdistrict: "Subdistrict",
      island: "Island",
      courier: "Courier",
      post_code: "Post code",
      destination_code: "Destination code",
      lead_time: "Lead time",
      lead_time_text: "Lead time text",
      province: "Province",
      delivery_cost: "Delivery cost",
      return_cost: "Return cost",
      instance_fee: "Instance fee",
      cod_fee: "Cod fee",
      admin_fee: "Admin fee",
      is_qualified: "Is qualified",
      to_check: "To check",
      is_contacted: "Is contacted",
      affbay_status: "Affbay status",
      is_visible: "Is visible",
      is_top: "Is top",
      is_desktop: "Is deskop",
      is_mobile: "Is mobile",
      url: "URL",
      tracker_id: "Tracker ID",
      tracker_type: "Tracker type",
      focus_ID: "Focus ID",
      instance: "Instance",
      signature: "Signature",
      value: "Value",
      courier_status: "Courier status",
      status_description: "Status description",
      notes: "Notes",
      additional_info: "Additional inf",
      external: "External",
      transactionType: "Transaction type",
      transactionStatus: "Transaction status",
      customVariables: "Custom variables",
      systemVariables: "System Variables",
      newVariable: "New variable",
      affbay_lead_id: "Affbay lead ID",
      amount: "Amount (USD)",
      optional_description: "Optional description",
      title: "Title",
      is_home_page: "Visible on dashboard",
      is_home_page_short: "On dashboard",
      news_type: "News type",
      visible_on_home_page: "Visible on dashboard",
      date_from: "Start form",
      date_from_short: "Start",
      date_to: "End form",
      date_to_short: "End",
      time: "Time",
      hour: "Hour",
      day: "Day",
      week: "Week",
      month: "Month",
      year: "Year",
      today: "Today",
      lastWeek: "Last week",
      lastMonth: "Last month",
      acceptedBalance: "Accepted Balance",
      acceptedBalanceExplanation: "(Sum of filtered records)",
      actualBalance: "Actual Balance",
      requestPayout: "Request Payout",
      notAcceptedBalance: "Not Accepted (Pending) Transactions:",
      earningsChart: "Earnings Chart",
      generatedOn: "Generated on",
      groupBy: "Group by",
      codeName: "Code name",
    },
    informations: {
      active: "Active",
      inactive: "Inactive",
      online: "Online",
      offline: "Offline",
      viewPassword: "View password",
      notAvailable: "n/a",
      qualified: "Qualified",
      notQualified: "Not qualified",
      sessionExpired: "Session expired",
      visible: "Visible",
      invisible: "Invisible",
    },
    modalButtonsTitle: {
      edit: "Edit",
      createNewInstance: "Add",
      createNewClassifier: "Add",
      addNewPage: "Add page",
      addNewCompany: "Add company",
      addNewPartner: "Add new Partner",
      addNewUser: "Add user",
      editUser: "Edit users",
      addNewRole: "Add new role",
      editRole: "Edit role",
      addNewCountry: "Add new country",
      addNewCity: "Add new city",
      addNewCourier: "Add new courier",
      addNewStatus: "Add new status",
      addNewLocation: "Add new location",
      addNewPostCode: "Add new post code",
      addNewMethod: "Add new method",
      addNewProvince: "Add new province",
      addNewChannel: "Add new channel",
      addNewSubdistrict: "Add new subdistrict",
      addNewCategory: "Add new category",
      addNewCampaign: "Add new campaign",
      editCampaign: "Edit campaign",
      addNewLeadsRedirection: "Add",
      addNewSystemVariable: "Add new variable",
      addNewTrafficSource: "Add new variable",
      addNewCouriersApiMapping: "Add new mapping",
      addNewCouriersStatuses: "Add new status",
      addNewSystemClassifier: "Add",
      deposit: "Deposit",
      withdraw: "Withdraw",
      addNew: "Add new",
    },
    modalTitle: {
      edit: "Edit",
      editPage: "Edit page",
      addNewCompany: "Add company",
      addNewUser: "Add new user",
      editUser: "Edit users",
      addNewRole: "Add new role",
      editRole: "Edit role",
      addNewCountry: "Add new country",
      editCountry: "Edit country",
      addNewCity: "Add new city",
      addNewCourier: "Add new courier",
      editCourier: "Edit courier",
      addNewDistrict: "Add new district",
      addNewStatus: "Add new status",
      editStatus: "Edit status",
      addNewLocation: "Add new location",
      editLocation: "Edit location",
      addNewPostCode: "Add new post code",
      editPostCode: "Edit post code",
      addNewMethod: "Add new method",
      editPaymentMethod: "Edit payment method",
      addNewCategory: "Add new category",
      editCategory: "Edit category",
      addNewChannel: "Add new channel",
      editChannel: "Edit channel",
      addNewSubdistrict: "Add new subdistrict",
      editSubdistrict: "Edit district",
      addNewProvince: "Add new province",
      deleteProvince: "Delete province",
      editCampaign: "Edit campaign",
      editLeadsRedirection: "Edit leads redirection",
      addNewSystemVariable: "Add new system variable",
      addNewTrafficSource: "Add new traffic source",
      addNewCouriersApiMapping: "Add new couriers api mapping",
      addNewCouriersStatuses: "Add new couriers status",
      editCouriersStatus: "Edit couriers status",
      editCouriersApiMapping: "Edit couriers api mapping",
      addNewSystemClassifier: "Add new system classifier",
      editSystemClassifier: "Edit system classifier",
      editTrafficSource: "Edit traffic source",
      deposit: "Deposit",
      withdraw: "Withdraw",
      transaction: "Transaction",
      addNew: "Add new",
      edit_news: "Edit news",
      sendFeedback: "Send feedback",
    },
    modalTooltips: {
      see_company_members: "See company members",
      addNewUser: "Add new user",
      addNewRole: "Add new role",
      addNewCountry: "Add new country",
      editCountry: "Edit country",
      addNewCity: "Add new city",
      editCity: "Edit city",
      addNewCourier: "Add new courier",
      editCourier: "Edit courier",
      addNewStatus: "Add new status",
      editStatus: "Edit status",
      addNewLocation: "Add new location",
      editLocation: "Edit location",
      addNewPostCode: "Add new post code",
      editPostCode: "Edit post code",
      addNewMethod: "Add new method",
      editCategory: "Edit category",
      addNewChannel: "Add new channel",
      editChannel: "Edit channel",
      addNewSubdistrict: "Add new subdistrict",
      editSubdistrict: "Edit district",
      addNewProvince: "Add new province",
      deleteProvince: "Delete province",
      addNewCategory: "Add new category",
      addNewSystemVariable: "Add new system variable",
      addNewTrafficSource: "Add new traffic source",
      editTrafficSource: "Edit traffic source",
      addNewCouriersApiMapping: "Add new couriers api mapping",
      addNewCouriersStatuses: "Add new couriers status",
      editCouriersApiMapping: "Edit couriers api mapping",
      editCouriersStatus: "Edit couriers statuse",
      addNewSystemClassifier: "Add new system classifier",
      editSystemClassifier: "Edit system classifier",
      filters: "Filters",
      deposit: "Deposit",
      withdraw: "Withdraw",
    },
    tables: {
      campainFocusTable: "CampainFocusTable",
    },
    title: {
      edit_account_manager: "Edit account manager",
      createNewInstance: "Create new instance",
      createNewClassifier: "Create new classifier",
      editClassifier: "Edit Classifier",
      editPartner: "Edit Partner",
      editEmployee: "Edit Employee",
      editCompany: "Edit company",
      addNewPartner: "Add new Partner",
      addNewEmployee: "Add new Employee",
      addNewCompany: "Add new company",
      selectInstance: "Select instance",
      selectInstanceAndCampaign: "Select instance and campaign",
      campaignsAllInstances: "Campaigns in all instances",
      all: "All",
      allArrowAll: "All -> All",
      allArrow: "All ->",
      arrowAll: "-> All",
      createNewProduct: "create new product",
      campaignsActivityStatus: `Change classifier activity status`,
      // partnerActivityStatus: `Change Partner activity status`,
      // employeesActivityStatus: `Change Employee activity status`,
      userActivityStatus: `Change users activity status`,
      filters: "Filters",
      activityStatus: `Change activity status`,
      addNewPage: "Add new page",
      addNewCampaign: "Add new campaign",
      addNewLeadsRedirection: "Add new leads redirection",
      notFoundLabel: "Sorry, the page you visited does not exist.",
      addNew: "Add new",
      new: "New",
      change: "Change",
      inProgressTitle: "Page in progress",
      inProgressDesc: "Sorry, the page you visited is still in progress.",
    },
    columnTitle: {
      to_check: 'To check',
      product_sku: "Product SKU",
      product_sku_short: "SKU",
      comment: "Comment",
      classifier_end: "Classifier end",
      phone_origin: "Phone (origin)",
      id: "ID",
      type: "Type",
      country: "Country",
      country_short: "Co.",
      name: "Name",
      second_name: "Second name",
      short_name: "Short name",
      campaign_name: "Campaign name",
      domain: "Domain",
      user: "User",
      password: "Password",
      password_short: "Pass",
      url: "Base campain URL",
      url_short: "URL",
      active: "Actual campaign activity status",
      active_short: "Active",
      // classifier_is_qualified: 'Actual classifier qualified status',
      // classifier_is_qualified_short: 'Qualified',
      online: "Actual campaign online status",
      online_short: "Online",
      created: "Date created",
      created_short: "Created",
      updated: "Recently Updated",
      updated_short: "Updated",
      options: "Options",
      instance: "Instance",
      focus_instance: "Focus instance",
      classifiers_external: "Classifiers external",
      affbay_status: "Affbay status",
      status: "Status",
      qualified: "Qualified",
      approvedAt: "Approved",
      approvedAt_short: "Date approved at",
      classifier: "Classifier",
      product: "Product",
      partner: "Partner",
      payout: "Payout",
      payout_scheme: "Payout Schema",
      payout_scheme_short: "Scheme",
      phone: "Phone",
      click_id: "click_id",
      pub_id: "Pub ID",
      lp_url: "Lp URL",
      role: "Role",
      description: "Description",
      application_access: "Application access",
      avalable_in_affbay: "Available in Affbay",
      avalable_in_affbay_short: "In Affbay",
      avalable_for_sale: "Available for sale",
      avalable_for_sale_short: "For sale",
      base_price: "Base prices",
      base_price_short: "Price",
      visible_for_partners: "Visible for partners",
      visible_for_partners_short: "For partners",
      categories: "Categories",
      landing: "Landing",
      preview: "Preview",
      is_visible: "Visible",
      is_top: "Top",
      is_mobile: "Mobile",
      is_desktop: "Desktop",
      deleted_at: "Deleted",
      accountCounter: "Account counter",
      isInternal: "Internal",
      partnerToken: "Partner token",
      email: "Email",
      manager: "Manager",
      company: "Company",
      skype: "Skype",
      telegram: "Telegram",
      is_active_short: "Active",
      key: "Key",
      focusId: "Focus ID",
      is_operative: "Operative",
      address: "Address",
      code: "Code",
      currency: "Currency",
      is_active: "Active",
      name_eng: "Name (eng)",
      bank_transfer: "Bank transfer",
      city: "City",
      province: "Province",
      district: "District",
      subdistrict: "Subdistrict",
      postCode: "Post code",
      courier: "Courier",
      lead_time: "Lead time",
      lead_time_text: "Lead time text",
      district_code: "District code",
      island: "Island",
      deliver_cost: "Deliver cost",
      resturn_cost: "Return cost",
      insurance_fee: "Insurance fee",
      code_fee: "Code fee",
      admin_fee: "Admin fee",
      dalivery_cost: "Delivery cost",
      permission_id: "Permission ID",
      is_qualified: "Qualified",
      is_contacted: "Contacted",
      is_qualified_short: "Qualified",
      value: "Value",
      courier_name: "Courier name",
      courier_name_short: "Courier",
      courier_status: "Courier status",
      status_description: "Status description",
      notes: "Notes",
      external: "External",
      additional_inf: "Additional informations",
      additional_inf_short: "Informations",
      view: "View",
      is_manual_short: "Manual",
      is_manual: "Is Manual",
      is_successful_short: "Successful",
      is_successful: "Is Successful",
      response_time_short: "Time",
      response_time: "Response time",
      response_status_short: "Status",
      response_status: "Response Status",
      response_text_short: "Text",
      response_text: "Response Text",
      title: "Title",
      is_home_page: "Visible on dashboard",
      is_home_page_short: "On dashboard",
      news_type: "News type",
      visible_on_home_page: "Visible on dashboard",
      date_from: "Start form",
      date_from_short: "Start",
      date_to: "End form",
      date_to_short: "End",
      scheme: "Scheme",
      leadId: "Lead ID",
      accepted: "Accepted",
      date: "Date",
      params: "Params",
      exclude_from_double: "Exclude from double",
      exclude_from_double_short: "Double Excl.",
    },
    tooltips: {
      see_company_members: "See company members RU",
      hide_postbacks: "Hide postbacks",
      show_postbacks: "Show postbacks",
      copyKey: "Copy key",
      editPage: "Edit page",
      deletePage: "Delete page",
      cantDeleteAdminSuperAdmin: `Can't delete Admin/Super admin`,
      userActivityStatus: "Change users activity status",
      deleteUser: "Delete user",
      changeRoleActiveStatus: "Change roles sctive status",
      editRole: "Edit role",
      deleteRole: "Delete role",
      changeActiveStatus: "Change active status",
      viewCampaign: "View campaign",
      viewCompany: "View company",
      changeQualifiedStatus: "Change qualified status",
      deleteStatus: "Delete status",
      deletePostCode: "Delete post code",
      deleteCstegory: "Delete category",
      change_activity_status: "Change activity status",
      delete_leads_direction: "Delete direction",
      edit_leads_direction: "Edit leads redirection",
      delete: "Delete",
      edit: "Edit",
      accept: "Accept",
      reject: "Reject",
    },
    statement: {
      unrecognized_error: "Unrecognized error",
      refresh_page: "Refresh page",
      your_account_has_been_created: "Your account has been activated",
      your_account_has_been_created_message:
        "Now you need to wait for our specialist to accept your account. ",
      sorry_we_could_not_get_your_data: `Sorry, we couldn't get your data`,
      databaseConnectionError:
        "Something went wrong while connecting to the database!",
      databaseConnectionSuccess: "Udane pobranie z bazy danych!",
      instanceCreated: "Udane dodanie instancji!",
      classifierCreated: "Classifier created!",
      classifierUpdated: "Classifier updated!",
      thePropsAreTheSame:
        "Wartości w polach nie uległy zmianie - do ustalenia co z tym robimy",
      campaignUpdated: "Udana aktualizacja kampanii!",
      qulifiedUpdate: "Udana zmiana kwalifikacji",
      app_user_updated: "App user updated!",
      app_user_created: "App user created!",
      status_updated: "Status updated!",
      there_was_a_problem_removing_app_user:
        "There was a problem removing app user",
      app_user_deleted: "App user deleted",
      there_was_a_problem_removing_role: "There was a problem removing role",
      there_was_a_problem_removing_permission:
        "There was a problem removing permission",
      there_was_a_problem_creating_role: "There was a problem creating role",
      user_role_updated: "User role updated!",
      companie_created: "Companie created!",
      companie_updated: "Companie updated!",
      there_was_a_problem_removing_city: "There was a problem removing city",
      city_deleted: "City deleted!",
      city_created: "City created!",
      city_updated: "City updated!",
      there_was_a_problem_removing_country:
        "There was a problem removing country",
      country_created: "Country deleted!",
      country_updated: "Companie updated!",
      there_was_a_problem_removing_courier:
        "There was a problem removing courier",
      courier_deleted: "Courier deleted!",
      courier_updated: "Courier updated!",
      courier_created: "Courier created!",
      there_was_a_problem_removing_district:
        "There was a problem removing district",
      district_deleted: "District deleted!",
      district_updated: "District updated!",
      district_created: "Dictrict created!",
      there_was_a_problem_removing_location:
        "There was a problem removing location",
      location_deleted: "Location deleted!",
      location_updated: "Location updated!",
      location_created: "Location created!",
      there_was_a_problem_removing_payment_method:
        "There was a problem removing payment method",
      payment_method_deleted: "Payment method deleted!",
      payment_method_updated: "Payment method updated!",
      payment_method_created: "Payment method created!",
      there_was_a_problem_removing_post_code:
        "There was a problem removing post code",
      post_code_deleted: "Post code deleted!",
      post_code_updated: "Post code updated!",
      post_code_created: "Post code created!",
      there_was_a_problem_removing_product_category:
        "There was a problem removing product category",
      product_category_deleted: "Product category deleted!",
      product_category_updated: "Product category updated!",
      product_category_created: "Product category created!",
      there_was_a_problem_removing_province:
        "There was a problem removing province",
      province_deleted: "Province deleted!",
      province_updated: "Province updated!",
      province_created: "Province created!",
      there_was_a_problem_removing_sales_channel:
        "There was a problem removing sales channel",
      sales_channel_deleted: "Sales channel deleted!",
      sales_channel_updated: "Sales channel updated!",
      sales_channel_created: "Sales channel created!",
      there_was_a_problem_removing_status:
        "There was a problem removing status",
      status_deleted: "Status deleted!",
      status_created: "Status created!",
      there_was_a_problem_removing_subdistrict:
        "There was a problem removing subdistrict",
      subdistrict_deleted: "Subdistrict deleted!",
      subdistrict_updated: "Subdistrict updated!",
      subdistrict_created: "Subdistrict created!",
      employee_updated: "Employee updated!",
      employee_created: "Employee created!",
      filters_confirmed: "Filtry potwierdzone",
      filters_have_been_reset: "filters have been reset",
      there_was_a_problem_removing_page: "There was a problem removing page",
      page_deleted: "Page deleted!",
      page_updated: "Page updated!",
      page_created: "Page created!",
      partner_updated: "partner updated!",
      partner_created: "partner created!",
      change_permission_status: "Change permissions status",
      there_was_a_problem_removing_campaign:
        "There was a problem removing campaign",
      there_was_a_problem_creating_campaign:
        "There was a problem creating campaign",
      campaign_deleted: "Campaign deleted!",
      campaign_updated: "Campaign updated!",
      campaign_created: "Campaign created!",
      role_deleted: "Role deleted!",
      choose_out_of_scope: "Choose out of the scope!",
      date_changed: "Date changed",
      session_expired: "Session expired",
      session_expired_description:
        "Your session has expired. Please login again to continue working",
      incorrect_login_information:
        "Your login information was incorrect. Please check and try again",
      there_was_a_problem_removing_system_variable:
        "There was a problem removing system variable",
      system_variable_deleted: "System variable deleted!",
      system_variable_updated: "System variable updated!",
      system_variable_created: "System variable created!",

      traffic_source_deleted: "Traffic source deleted!",
      traffic_source_updated: "Traffic source updated!",
      traffic_source_created: "Traffic source created!",

      there_was_a_problem_removing_couriers_api_mapping:
        "There was a problem removing couriers api mapping",
      couriers_api_mapping_deleted: "Couriers api mapping deleted!",
      couriers_api_mapping_updated: "Couriers api mapping updated!",
      couriers_api_mapping_created: "Couriers api mapping created!",
      system_classifier_created: "System classifierl created!",
      system_classifier_updated: "System classifierl updated!",
      updated: "Updated!",
      created: "Created!",
      deleted: "Deleted!",
      success: "Success!",
      accepted: "Accepted!",
      rejected: "Rejected",
      noCompanyIdAssigned:
        "Your account has no company assigned. Please contact us through support panel.",
      noDataToDisplay: "Sorry, there is no data to display",
    },
    tabs: {
      createNewProduct: "create new product",
      pricing: "Pricing",
      conversion: "Conversion",
      affbayVisibility: "Affbay visibility",
      landings: "Landings / Prelandings",
    },
    navigationItems: {
      dashboard: "Dashboard",
      statistics: "Statistics",
      financeStatistics: "Finance statistics",
      leads: "Leads",
      employees: "Employees",
      products: "Products",
      pages: "Pages",
      landings: "Landings",
      companies: "Companies",
      partners: "Partners",
      campaigns_partner: "Campaigns",
      campaigns_admin: "Campaigns admin",
      finance: "Finance",
      financeAdmin: "Finance admin",
      tools: "Tools",
      news: "News",
      news_admin: "News admin",
      support: "Support",
      faq: "FAQ",
      contact: "Contact",
      login: "Login",
      register: "Register",
      offers: "Offers",
      create_new_order: "Create new order",
      orders: "Orders",
      couriersStatusMapping: "Status mapping",
      account_managers: "Account Managers",
    },
    subNavigationItem: {
      k8_services: "K8 Services",
      sales: "Sales",
      sales_p2: "P2",
      sales_resale: "P3",
      sales_winback: "Winback",
      administration: "Administration",
      appRemarketing: "Remarketing - P2",
      appUsers: "Users",
      appUsersRole: "Users role",
      webSocket: "WebSocket",
      appUsersPermissions: "Users permissions",
      // Call center
      callCenter: "Call center",
      cc_instances: "Instances",
      cc_campaigns: "Campaigns",
      cc_classifiers: "Classifiers",
      li_classifiers_cc: "Mapping classifiers",
      cc_webhooks: "Webhooks",
      cc_leads_direction: "Leads redirection",
      // Settings
      setting: "Setting",
      api: "API",
      payment: "Payment",
      postbacks: "Postbacks",
      referralHistory: "Referral history",
      security: "Security",
      settings: "Settings",
      // Dictionaries
      dictionaries: "Dictionaries",
      countries: "Countries",
      cities: "Cities",
      couriers: "Couriers",
      districts: "Districts",
      subdistricts: "Subdistricts",
      statuses: "Statuses",
      locations: "Locations",
      postCodes: "Post cods",
      paymentMethods: "Payment methods",
      productCategories: "Product cat.",
      salesChannels: "Sales channels",
      provinces: "Provinces",
      system_variables: "System variables",
      traffic_sources: "Traffic sources",
      couriers_api_mapping: "Couriers mapping",
      couriers_statuses: "Couriers statuses",
      system_classifiers: "System classifiers",
    },
    others: {
      of: "of",
      rows: "rows",
    },
    contentTitle: {
      support: "Support",
    },
    contentText: {
      alreadyHaveAnAccount: "Already have an account?",
      iDontHaveAnAccount: "I don't have an account.",
      rememberThePassword: "Remember the password?",
      support:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us.",
    },
    links: {
      all_news: "All news",
      login: "Login",
      registration: "Registration",
      forgotYourPassword: "Forgot your password?",
    },
    placeholders: {
      enterName: "Enter name",
      enterEmail: "Enter e-mail",
      enterPassword: "Enter password",
      repeatPassword: "Repeat password",
      selectCountry: "Select country",
      notRequired: "Not required",
      select: "Select...",
      globalSearch: "Global search",
      search: "Search",
      findProduct: "Find product",
      choose_affbay_status: "Choose affbay status",
      choose_country: "Choose country",
      choose_company: "Choose company",
      choose_product: "Choose product",
      choose_partner: "Choose partner",
      choose_scheme: "Choose scheme",
      choose_classifier: "Choose classifier",
      choose_remarketing_campaign: "Choose campaign",
      enter_min_4_letters: "Entere min 4 letters",
    },
    pageTitle: {
      k8_services: "K8 Services",
      dashboard: "Dashboard",
      account_managers: "Account managers",
      loginContent: "Log In",
      registerContent: "Registration",
      resetPasswordContent: "Restore password",
      employees: "All employees PL + All employees Call center",
      products: "Products",
      pages: "Pages",
      addNewCompany: "Add company",
      companies: "Companies",
      partners: "Partners",
      administrationUsers: "Administration - Users",
      administrationUsersRole: "Administration - Users Role",
      instances: "Instances",
      instancesForCCSimplified: "Call center API connection",
      campaigns: "Campaigns",
      classifiers: "Classifiers",
      mappingClassifiers: "Mapping classifiers",
      webhooks: "Webhooks",
      countriesDictionary: "Countries dictionary",
      citiesDictionary: "Cities dictionary",
      couriersDictionary: "Couriers dictionary",
      districtsDictionary: "Districts dictionary",
      statusesDictionary: "Statuses dictionary",
      locationsDictionary: "Locations dictionary",
      postCodeDictionary: "Post codes dictionary",
      paymentMethodDictionary: "Payment methods dictionary",
      productCategoriesDictionary: "Product categories dictionary",
      salesChannels: "Sales channels dictionary",
      subdistrictsDictionary: "Subdistricts dictionary",
      provincesDictionary: "Provinces dictionary",
      campaigns_admin: "Campaigns - Admin",
      orders: "Orders",
      leads_directions: "Leads redirections",
      system_variables: "System variables",
      traffic_sources: "Traffic sources",
      couriers_api_mapping: "Couriers api mapping",
      system_classifiers: "System classifiers",
      finance: "Finance",
      news_admin: "News Admin",
      finance_admin_view: "Finance admin view",
      resaleP3: "P3 Resale",
      resaleP2: "P2 Resale",
    },
    productsFormMain: {
      editProduct: "Edit product",
      createNewProduct: "Create new product",
      englishName: "English name",
      currency: "Currency",
      callCenter: "Call Center",
      sku: "SKU",
      originalName: "Original name",
      shortName: "Short name",
      printName: "Print name",
      categories: "Categories",
      availableInAffbay: "Available in AFFBAY",
      testProduct: "Test product",
      availableForSale: "Available for sale",
      active: "Active",
      country: "Country",
      sales: "Sales",
      mainCamapignId: "Main campaign [id]",
      resellCampaignId: "Resell campaign [id]",
      productionCost: "Production cost (USD)",
      productionCostLocalCurrency: "Production cost (local currency)",
      affbayVisibility: "Affaby Visibility",
      visibleForPartners: "Visible for partners",
      visibleOnHomePage: "Visible on home page (top offers)",
      isRestricted: "Is restricted",
      defaultGlobalPayout: "Default global payout (USD)",
      defaultPayoutScheme: "Default payout scheme",
      cpa: "CPA",
      cpl: "CPL",
      landingPriceForConsumer: "Landing price for consumer",
      approveVisibleOnPage: "Approve visible on page (%)",
      payoutVisibleOnPage: "Payout visible (string) on page (USD)",
      descriptionEN: "Description EN",
      descriptionRU: "Description RU",
      traffic_sources_en: "Traffic sources (description) EN",
      traffic_sources_ru: "Traffic sources (description) RU",
      terms_en: "Terms EN",
      terms_ru: "Terms RU",
      conditions_en: "Conditions EN",
      conditions_ru: "Conditions RU",
      productsWillNotBeAvailableInAffbay:
        "Products will not be available in AFFBAY",
      updateCampaigns: "Refresh campaings list",
      save: "Save",
      create: "Create",
      reset: "Reset",
    },
    checkBoxes: {
      iWantToReceiveImportantNewsByEmail:
        "I want to receive important news by email (recommended)",
      iWantToReceivePromotionslNewsByEmail:
        "I want to receive promotional news by email",
      iWantToReceiveInformationAboutChangesInOffersByEmail:
        "I want to receive information about changes in offers by email",
      iWantToReceiveOffersOfOffersByEmail:
        "I want to receive offers of offers by email",
      add_to_affbay_classifiers_library: "Add to Affbay Classifiers library",
    },
    landingPage: {
      about: "About",
      topOffers: "Top offers",
      contact: "Contact",
      logIn: "Log In",
      certifiedProducts: "CERTIFIED PRODUCTS FROM DIRECT ADVERTISER",
      afiliateNetwork: "Affiliate Network in Thailand",
      saudiArabAndIndonesia: "Saudi Arabia and Indonesia",
      becomeAPartner: "Become a partner",
      activePartners: "Active partners",
      offersInAsia: "Offers in Asia",
      yearsOnTheMarket: "Years on the market",
      countries: "Countries",
      aboutText:
        "Affbay is a direct advertiser in the markets of Asia and the Arab countries. We do not resell the offers, all products are our own. Here you will find dozens of offers on the topic of beauty, health and weight loss with payments from $ 18 higher for a confirmed application. We work on models CPA, CPL, CPI.",
      regularPayouts: "Regular Payouts",
      regularPayoutsText: "Payouts without delay on most payment systems.",
      highPayouts: "High Payouts",
      highPayoutsText: "From $ 18 and above for a confirmed application.",
      ownCallCenter: "Own call center",
      ownCallCenterText:
        "Experienced CC specialists performing work efficiently.",
      personalManager: "Personal manager",
      personalManagerText:
        "Responsive support will help you solve any problems.",
      weDontHaveTopOffers: "We don't have any top offers right now.",
      howCanWeHelpYou: "How can we help you?",
      writeUs: "Write us about your concerns.",
      becomeOurPartner: "Become our partner and start earning right now",
      affbayAsia: " Affbay.asia",
      ownedBy: "Owned By: Izabella Frolushkin FZE",
      privacyPolicy: "Privacy Policy",
      termsAndConditions: "Terms and Conditions",
      send: "Send",
      name: "Name",
      email: "E-mail",
      object: "Object",
      message: "Message",
      thankYou: "Thank you",
      youWillBeHearing:
        "Your message was sent. We will handle your issue with 48 hours.",
      startEarning: "Start earning",
      sitePrice: "Site price",
      approve: "Approve",
      payouts: "Payouts",
      country: "Country",
    },
    loginPage: {
      logIn: "Log In",
      registration: "Registration",
      email: "E-mail",
      password: "Password",
      forgotYourPassword: "Forgot your password?",
      logIntoThePlatform: "Log into the platform",
      iDontHaveAnAccount: "I don't have an account.",
      send_request_again: "Send request again",
    },
    registrationPage: {
      registration: "Registration",
      name: "Name",
      email: "E-mail",
      password: "Password",
      repeatPassword: "Repeat password",
      country: "Country",
      skype: "Skype",
      telegram: "Telegram",
      notRequired: "Not required",
      iAgreeToAffbayAsia: "I agree to Affbay Asia",
      privacyPolicy: "Privacy Policy",
      and: "and",
      termsAndConditions: "Terms and Conditions",
      nextStep: "Next Step",
      alreadyHaveAnAcount: "Already have an account?",
      logIn: "Log in",
      thxForReg: "Thank you for registration",
      toStartWork: "To start work please provide us few information",
      surveyIsNotAvailable: "Survey is not available",
      thisAccountIsA: "This account is a...",
      whichgeos: "Which geos you have worked with before?",
      whatgeo: "What geos do you want to work with?",
      whatTypeOfTraffic: "What type of traffic are you using?",
      whatBugdets: "What budgets did you work with?",
      whatDoYouPrefer: "What do you prefer - S2S or API?",
      submitMyApplication: "Submit my application",
      enterYourName: "Enter your name",
      enterEmail: "Enter e-mail",
      enterPassword: "Enter password",
    },
    submitSuccess: {
      congrats: "Congratulations, your account has been created!",
      nowYouCan: "Now you can login to your account",
      logIn: "Log In",
    },
    resetPassword: {
      password_changed: "Your password has been changed. You can login now.",
      typeTheAdress:
        "Type the address linked to your account and we'll send you password reset instructions. Also, check your spam folder.",
      email: "E-mail",
      set_new_password: "Set new password",
      remember: "Remember your password?",
      logIn: "Log In",
      checkEmail: "Check your e-mail",
      withinAFew:
        "Within a few minutes you will receive an email. In this message we will send you a link to set new password.",
      new_password: "New password",
    },
    supportView: {
      support: "Support",
      ifYouNeedHelp:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us.",
      subject: "Subject",
      message: "Message",
      send: "Send",
    },
    newsView: {
      news: "News",
      allNews: "All news",
    },
    offersView: {
      offers: "Offers",
      offersFound: "offers found",
      show: "Show:",
      sortBy: "Sort by:",
      category: "Category",
      conversionType: "Conversion type",
      sort: "Sort",
      accessRequest: "Access Request",
      wirteUs:
        "Write us about traffic sources, with whom you work and your experience.",
      send: "Send",
      thankYou: "Thank you",
      youWillBeHearing: "You will be hearing from us soon!",
      sitePrice: "Site price",
      payouts: "Payouts",
      country: "Country",
      originalCountry: "Original country",
      readMore: "Read more",
      exampleCodePhp: "Download example",
    },
    offersDetails: {
      sku: "SKU",
      // tabs
      description: "Description",
      targetURL: "Target URL",
      postbacks: "Postbacks",
      trafficSources: "Traffic sources",
      news: "News",
      terms_and_conditions: "Terms & conditions",
      landings_tab: "Landings",
      // product card
      payout: "Payout",
      sitePrice: "Site price",
      category: "Category",
      country: "Country",
      devices: "Devices",
      // target url
      step_1:
        "Step 1. Choose landing page and prelanding. You can choose multiple items which be opened randomly in your campaign",
      step_2:
        "Step 2. Enter new name for your campaign and click Generere URL button to save it.",
      landings: "Landings",
      prelandings: "Prelandings",
      campaign_name: "Campaign name",
      generateURL: "Generate URL",
      allProductCampaigns: "All product campaigns",
      name: "Name",
      prelanding: "Prelanding",
      landing: "Landing",
      URL: "URL",
      youHaveTo: "You have to choose LP and type Campaign name",
      noLandings: "No pages to display",
      // postbacks
      urlForSales: "Postback URL for Sales (means APPROVE for CPL)",
      urlForHolds: "Postback URL for Holds (means APPROVE for CPL)",
      urlForRejects: "Postback URL for Rejects (means APPROVE for CPL)",
      urlForTrash: "Postback URL for Trash (means REJECTED for CPL)",
      urlForDuplicated: "Postback URL for Duplicated (means REJECTED for CPL)",
      urlForExcluded: "Postback URL for Excluded (means REJECTED for CPL)",
      urlForValidated: "Postback URL for Validated",
      urlForCheck: "Postback URL for Check",
      googleTrackingID: "Google tracking ID",
      facebookPixelID: "Facebook pixel ID",
      customPixelID: "Custom pixel ID",
      useGlobalPostbacks: "Use global postbacks",
      createPostback: "Create postback",
      updatePostback: "Update postback",
      fillInTheData: "Fill in the data below to create a new postback",
      protocol: "Protocol",
      domain: "Domain",
      exampleCOM: "example.com",
      extraPathParameter: "Extra path parameter",
      folderSlash: "/folder/subfolder",
      parameter: "Parameter",
      addNew: "Add new",
      postbackURLPreview: "Postback URL Preview",
      save: "Save",
      basic: "Basic",
      advanced: "Advanced",
      postbackSavedSuccessfully: "Postback saved successfully",
      postbackSavedWithWarning: "Postback saved with warning",
      postbackNotSaved: "Unable to saved postback",
      clearPostback: "Clear Postback",
    },
    profileView: {
      personlaData: "Personal data",
      changePassword: "Change password",
      notifications: "Notofications",
      apitoken: "API Token",
      // personal data
      email: "E-mail",
      name: "Name",
      phone: "Phone number",
      telegram: "Telegram",
      skype: "Skype",
      timeZone: "Time zone",
      apply: "Apply",
      cancel: "Cancel",
      id_referral: "Registration link",
      // change password
      currentPass: "Current password",
      newPass: "New password",
      repeatNewPass: "Repeat new password",
      change: "Change",
      // notifications
      importantNews: "I want to receive important news by email (recommended)",
      promotionalNews: "I want to receive promotional news by emial",
      information:
        "I want to receive information about changes in offers by email",
      offers: "I want to receive offers of offers by email",
      customVariablesList: "Custom Variables List",
      postbacksList: "Postbacks List",
      // api token
    },
    toolsView: {
      open: "Open",
      tools: "Tools",
      // faq
      faq: "FAQ",
      answersToFAQ:
        "Answers to frequently asked questions regarding payments for their implementation and Affbay in general",
      readMore: "Read more",
      // API Documentation
      apiDocs: "API Documentation",
      leadSendExample: "Send lead example form",
      readOurDocs:
        "Read our documentation to get answers to many questions regarding the API.",
      readDocs: "Read documentation",
      // postback documentation
      postbackDocs: "Postback Documentation",
      postbacksNotify:
        "Read our documentation to get answers to many questions regarding the API.",
      // integration archi model
      integrationArchiModel: "Integration Architecture Model",
      hardToUnderstand:
        "Hard to understand how Affbay works? Read more about a few examples of our architecture.",
      // number validation
      numberValidation: "Number validation",
      checkingTheNumber:
        "Checking the number is the key to improving the quality of customer acquisition and revenue.",
      // longpage widget
      longpageFromWidget: "Longpage form widget",
      longpageFromWidgetDescription:
        "Include our form into your webpage. Simply paste our code, add your token and sku to start generating new leads.",
    },
    select: {
      select_timezone: "Select timezone",
      select_status: "Select status",
      select_available: "Select available",
      select: "Select",
      select_country: "Select country",
    },
    spin: {
      loading: "Loading...",
    },
    faq: {
      header_1: "What is Affbay Asia?",
      header_2: "What kinds of integration do we support?",
      header_3: "Do you need your own tracker to integrate by S2S?",
      header_4: "Which parameters are supported by Affbay (S2S integration)?",
      header_5: "How to add parameters you want to receive?",
      header_6: "Which parameter is Affbay’s?",
      header_7: "How to configure general postbacks for S2S?",
      header_8: "What is postback for sales, holds, rejected?",
      header_9: "How to configure getting postback for concrete offer?",
      header_10: "How to add payout to postback url?",
      header_11: "How should a postback url look like?",
      content_1:
        "We’re direct advertisers in Indonesia and Thailand, we have our own high conversion COD offers. More geos in Asia coming soon!",
      content_2:
        "We can integrate with you by S2S or by API. Documentation for API is provided on our website in section Offers (at the top of webpage). Choose offers you like and click Details.",
      content_3:
        "Affbay is a user friendly platform, which provides you with your personal account with dashboard and requires no tracker, although we can provide you S2S postback integration with your trackers.",
      content_4:
        'You can add any parameter that is supported by your tracker. See "How to add parameters you want to receive?".',
      content_5:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Just add any parameter without brackets {} () and put it also in your postback url.",
      content_6: [
        "Our built in parameters are:",
        "click_id - global identifier (UUID) of a specific lead",
        "campaign_id - global identifier (UUID) of a specific campaign",
        "aff_id - global identifier (UUID) of a specific user (you).",
        "If you specify a custom variable named as one of the above, postback will use it, substituting your tracker data instead of our default data. We discourage this to avoid confusion.",
      ],
      content_7:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Here you can add you general postback url. If you want to receive postbacks for sales – just put your postback url in this field, the same for postback for holds and rejected. Also you can add Facebook Pixel and Google Tracking ID for all the offers.",
      content_8:
        "If you want to receive postback for approved leads – choose postback for sales. Postback for holds means getting postback for every lead that came into our system. Postback for rejected means getting postback for leads that were rejected – invalid phone number or leads not being reached by Call Center in a month.",
      content_9:
        "Enter section Offers (at the top of webpage) -> choose offers and click Details -> there you can add postback urls and also Facebook Pixel and Google Tracking ID for specific offer.",
      content_10: "&amount=13&payout_type=pps¤cy=USD",
      content_11:
        "https://callbacks.bitterstrawberry.org/?token=0c4c1249825285a2997bacf09c09ec28&hash={hash}&transaction_id={click_id}&amount=13&payout_type=pps¤cy=USD",
    },
    news_type: {
      all_news: "All news",
      new: "New",
      important: "Important",
      info: "Info",
    },
    setting_page: {
      click_id: "Click ID",
      check_click_id: "Check click ID doubles",
      click_id_days: "Number of days to check",
    },
  },
  ru_RU: {
    validation: {
      current_password_is_incorrect: "Current password is incorrect",
      // Name
      nameIsRequired: "Укажите имя",
      // Last name
      lastNameIsRequired: "Укажите фамилию",
      // E-mail
      emailIsRequired: "Укажите e-mail",
      incorrectEmailAdres: "Неверный e-mail адресс",
      requiredUtf8Characters: "BRAK RU",
      // Password
      passwordIsRequired: "Необходимо ввести пароль",
      confirmPasswordIsRequired: "Необходимо ввести пароль еще раз",
      requiredTheSamePasswords: "Подтверждение не совпадает с паролем",
      passwordCondition:
        "Password must contain at least 1 upper case letter, 1 lower case letter, 1 numeric character, at least 2 special characters and must contain at least 8 characters.",
      // SkypeId
      skypeIdIsRequired: "Необходимо ввести Skype ID",
      // Telegram
      telegramIsRequired: "Необходимо ввести Telegram",
      // Country
      countryIsRequired: "Необходимо ввести страну",
      // Rest
      requiredMin3Characters: "Используйте хотя бы 3 символа",
      requiredMin8Characters: "Используйте хотя бы 8 символов",
      requiredMax15Characters: "Используйте максимум 15 символов",
      requiredMax20Characters: "Используйте максимум 20 символов",
      requiredMax30Characters: "Используйте максимум 30 символов",
      requiredMax50Characters: "Используйте максимум 50 символов",
      requiredMax191Characters: "Используйте максимум 191 символ",
      requiredMax250Characters: "Используйте максимум 250 символов",
      requiredMax255Characters: "Используйте максимум 255 символов",
      requiredAlphaNumeric: "Используйте буквы и цифры",
      statusIsRequired: "Необходим статус (номер)",
      requiredMax11Numbers: "Используйте максимум 11 чисел",
      required: "File is required RU",
      invalidDomain: "Domain invalid RU",
      duplicatedParam: "Duplicated parameter name RU",
      invalidParamName: "Invalid parameter name RU",
      invalidParamValue: "Invalid parameter value RU",
      invalidUrl: "Invalid URL RU",
      customInvalidUrlError:
        "IMPORTANT: This URL seems invalid but you can still save it. RU",
    },
    statistics: {
      to_check: 'To Check',
      toCheckDescription: 'Leads which were marked as Doubles for the last 30 days.',
      callCenterTitle: "Call center classifiers",
      totalDescription:
        "Validated, Send to Call Center, Awaiting, rejected, excluded and sold RU",
      awaitingDescription:
        "Classification pending (called leads by Call Center) RU",
      rejectedDescription: "Called but rejected RU",
      soldDescription: "Подтвержденная продажа",
      orderCreatedDescription: "Awaiting verification RU",
      trashDescription: "Неверные номера и т.д.",
      excludedDescription: "Не прозвоненный дубль",
      validatedDescription:
        "Validated by system but not sent to call center yet RU",
      sendToCcDescription: "Sent to call center but not called yet RU",
      doubledDescription: "Marked by system RU",
      verifiedApprovrdRateDescription:
        "((Order Created + Sold) / Total) * 100 RU",
      approvedRateDescription: "(Sold / Total) * 100 RU",
      leads: "Leads RU",
      total: "Всего",
      awaiting: "В ожидании",
      rejected: "Отклонено",
      tocheck: 'На проверке',
      sold: "Продано",
      orderCreated: "Order Created RU",
      trash: "Треш",
      excluded: "Исключено",
      validated: "Validated RU",
      sendToCC: "Send to Call Center RU",
      doubled: "Doubled RU",
      verifiedApprovrdRate: "Approve Rate (Potential) RU",
      approvedRate: "Verified Approve Rate RU",
      unrecognized: "Unrecognized RU",
      noMoney: "No money RU",
      noMoneyDescription: "No money Description RU",
      awaitingAll: "Awaiting all RU",
      awaitingAllDescription: "New lead, Validated, Awaiting, Send to CC RU",
      approved: "Approved RU",
      title: "Statistics leads RU",
      summary: "Summary statistics RU",
      numberOfLeadsByOffer: "Number of leads by offer RU",
      percentageOfApprovedLeads: "Percentage of approved leads RU",
    },
    tabPane: {
      details: "Детали",
      summary: "Сводка",
      personalData: "Персональные данные",
      changePassword: "Изменить пароль",
      notifications: "Уведомления",
      apiToken: "API Token",
      globalPostbacks: "Global postbacks RU",
      registrationLink: "Registration link RU",
    },
    buttons: {
      upload: "Upload",
      yes: "Yes RU",
      no: "No RU",
      confirm: "Confirm RU",
      today: "Сегодня",
      yesterday: "Вчера",
      thisMonth: "Этот месяц",
      lastMonth: "Последний месяц",
      last30Days: "Последние 30 дней",
      resetFilters: "Сбросить фильтры",
      addFilter: "Add filter RU",
      removeFilter: "Remove filter RU",
      clearAll: "Clear all RU",
      submit: "Применить",
      search: "Поиск",
      cancel: "Отменить",
      refreshTable: "Обновить таблицы",
      updateCampaign: "Обновить кампанию",
      reset: "Сбросить",
      register: "Регистрация",
      create: "Создать",
      login: "Логин",
      save: "Сохранить",
      save_new_password: "Восстановить пароль",
      send: "Отправить",
      apply: "Применить",
      generateToken: "Генерировать",
      addNewEmployee: "Add new employee RU",
      addNewProduct: "Add new product RU",
      add_new: "Add new RU",
      filter: "Filter RU",
      addVariable: "Add variable RU",
      goToMainPage: "Go to main page RU",
      download: "Download RU",
      downloading: "Downloading RU",
      new: "New RU",
      switchToUser: "Switch To RU",
      backToAdminAccount: "Back To Admin RU",
      setPostbackForAllProducts: "Set this postbacks for all products RU",
      show: "Show RU",
      hide: "Hide RU",
      enable: "Enable RU",
      disable: "Disable RU",
    },
    radioButtons: {
      leadsCreatedDate: "Лид создан",
      leadsApprovedDate: "Лид подтверден",
      leadsSoldDate: "Лид купил",
      leadsCallCenterLastCallDate: "Лид обзвонен кол-центром последний раз",
      salesCountryTimezone: "Часовой пояс",
      myTimezone: "Мой часовой пояс",
    },
    labels: {
      visibility: "Visibility RU",
      active_products: "Active products RU",
      inactive_products: "Inactive products RU",
      all_products: "All products RU",
      are_you_sure: "Are you sure? RU",
      do_you_want_to_generate_this_campaign:
        "Do you want to generate this campaign? RU",
      create_leads_on_order_delivered_status_global:
        "Create leads on order delivered status ( GLOBAL ) RU",
      partner_token: "Partner token RU",
      warnings: "Warnings RU",
      fails: "Fails RU",
      success: "Successes RU",
      comment: "Comment RU",
      selected: "Selected RU",
      items: "items RU",
      global_actions: "Global actions RU",
      partners_that_registerd_from_your_ref_link:
        "Partners that registerd from your ref link: RU",
      beauty: "Beauty RU",
      health: "Health RU",
      slimming: "Slimming RU",
      adult: "Adult RU",
      other: "Other RU",
      search: "Search RU",
      latest_news: "Latest News RU",
      top_offers: "Top offers RU",
      found: "Found RU",
      rows: "rows RU",
      balance: "Balance RU",
      hold: "Hold RU",
      your_manager: "Your manager RU",
      no_manager: "No manager RU",
      profile: "Profile RU",
      log_out: "Log Out RU",
      reportByTimezone: "Отчет по часовому поясу",
      dateType: "Тип дат",
      dateRange: "Диапазон дат",
      dateFrom: "Date from RU",
      dateTo: "Date to RU",
      setQuickDate: "Установить дату",
      selectedFilters: "Выбрать фильтры",
      status: "Статус",
      country: "Страна",
      product: "Продукт",
      lead_type: "Lead type",
      partner: "Партнер",
      userEmail: "E-mail пользователя",
      payoutScheme: "Схема выплат",
      remarketingCampaign: "Remarketing campaign",
      classifierName: "Название фильтра",
      accountManager: "Менеджер по работе с клиентами",
      selectTimeZone: "Выбрать часовой пояс",
      timezone: "Time zone RU",
      isActive: "Активен",
      isOnline: "Онлайн",
      type: "Тип",
      name: "Имя",
      domain: "Домен",
      user: "Пользователь",
      password: "Пароль",
      repeatPassword: "Повторите пароль",
      baseUrl: "Базовый URL",
      id: "ID",
      email: "E-mail",
      skype: "Skype",
      telegram: "Telegram",
      object: "Тема",
      message: "Сообщение",
      section: "Раздел",
      phone: "Телефон",
      currentPassword: "Текущий пароль",
      newPassword: "Новый пароль",
      repeatNewPassword: "Повторите новый пароль",
      apiToken: "API Token",
      click_id: "click_id",
      pub_id: "Pub ID",
      lp_url: "Lp URL",
      second_name: "Second name RU",
      short_name: "Short name RU",
      role: "Role RU",
      description: "Описание",
      application_access: "Application access RU",
      pages: "Pages RU",
      products: "Products RU",
      addressLine1: "Adress line 1 RU",
      addressLine2: "Adress line 2 RU",
      city: "City RU",
      state: "State RU",
      zipCode: "ZIP code RU",
      isInternal: "Internal RU",
      partnersAwaitingActivation: "Partners awaiting activation RU",
      allPartners: "All Partners RU",
      company: "Company RU",
      basedOnRole: "Based on role RU",
      campaigns: "Campaigns RU",
      instances: "Instances RU",
      currency: "Currency RU",
      code: "Code RU",
      address: "Address RU",
      operative: "Operative RU",
      name_eng: "Name (eng) RU",
      bank_transfer: "Bank transfer RU",
      district: "District RU",
      subdistrict: "Subdistrict RU",
      island: "Island RU",
      courier: "Courier RU",
      post_code: "Post code RU",
      destination_code: "Destination code RU",
      lead_time: "Lead time RU",
      lead_time_text: "Lead time text RU",
      province: "Province RU",
      delivery_cost: "Delivery cost RU",
      return_cost: "Return cost RU",
      instance_fee: "Instance fee RU",
      cod_fee: "Cod fee RU",
      admin_fee: "Admin fee RU",
      is_qualified: "Is qualified RU",
      to_check: "To check",
      is_contacted: "Is contacted RU",
      affbay_status: "Affbay status RU",
      is_visible: "Is visible RU",
      is_top: "Is top RU",
      is_desktop: "Is deskop RU",
      is_mobile: "Is mobile RU",
      url: "URL RU",
      tracker_id: "Tracker ID RU",
      tracker_type: "Tracker type RU",
      focus_ID: "Focus ID RU",
      instance: "Instance RU",
      signature: "Signature RU",
      value: "Value RU",
      courier_status: "Courier status RU",
      status_description: "Status description RU",
      notes: "Notes RU",
      additional_info: "Additional inf RU",
      external: "External RU",
      transactionType: "Transaction type Ru RU",
      transactionStatus: "Transaction status Ru",
      customVariables: "Custom variables RU",
      systemVariables: "System Variables RU",
      trafficSources: "Traffic Sources RU",
      newVariable: "New variable RU",
      affbay_lead_id: "Affbay lead ID RU",
      amount: "Amount (USD) RU",
      optional_description: "Optional description RU",
      title: "Title RU",
      is_home_page: "Visible on dashboard RU",
      is_home_page_short: "On dashboard RU",
      news_type: "News type RU",
      visible_on_home_page: "Visible on dashboard RU",
      date_from: "Start form RU",
      date_from_short: "Start RU",
      date_to: "End form RU",
      date_to_short: "End RU",
      time: "Time RU",
      hour: "Hour RU",
      day: "Day RU",
      week: "Week RU",
      month: "Month RU",
      year: "Year RU",
      today: "Today RU",
      lastWeek: "Last week RU",
      lastMonth: "Last month RU",
      acceptedBalance: "Accepted Balance RU",
      acceptedBalanceExplanation: "(Sum of filtered records) RU",
      actualBalance: "Actual Balance RU",
      requestPayout: "Request Payout RU",
      notAcceptedBalance: "Not Accepted (Pending) Transactions: RU",
      earningsChart: "Earnings Chart RU",
      generatedOn: "Generated on RU",
      groupBy: "Group by RU",
    },
    informations: {
      active: "Активный",
      inactive: "Не активный",
      online: "Онлайн",
      offline: "Оффлайн",
      viewPassword: "Посмотреть пароль",
      notAvailable: "Не доступно",
      qualified: "Квалифицированный",
      notQualified: "Неквалифицированный",
      sessionExpired: "Session expired RU",
      visible: "Visible RU",
      invisible: "Invisible RU",
    },
    modalButtonsTitle: {
      edit: "Edit RU",
      // createNewInstance: 'Создать новый образец',
      createNewInstance: "Add RU",
      // createNewClassifier: 'Создать новый фильтр',
      createNewClassifier: "Add RU",
      addNewPage: "Add page RU",
      addNewCompany: "Add company RU",
      addNewPartner: "Add new Partner RU",
      addNewUser: "Add user RU",
      editUser: "Edit users RU",
      addNewRole: "Add new role RU",
      editRole: "Edit role RU",
      addNewCountry: "Add new country RU",
      addNewCity: "Add new city RU",
      addNewCourier: "Add new courier RU",
      addNewStatus: "Add new status RU",
      addNewLocation: "Add new location RU",
      addNewPostCode: "Add new post code RU",
      addNewMethod: "Add new method RU",
      addNewProvince: "Add new province RU",
      addNewChannel: "Add new channel RU",
      addNewSubdistrict: "Add new subdistrict RU",
      addNewCategory: "Add new category RU",
      addNewCampaign: "Add new campaign RU",
      editCampaign: "Edit campaign RU",
      addNewLeadsRedirection: "Add RU",
      addNewSystemVariable: "Add new variable RU",
      addNewCouriersApiMapping: "Add new mapping RU",
      addNewCouriersStatuses: "Add new status RU",
      addNewSystemClassifier: "Add RU",
      deposit: "Deposit RU",
      withdraw: "Withdraw RU",
      addNew: "Add new RU",
    },
    modalTitle: {
      edit: "Edit RU",
      editPage: "Edit page RU",
      addNewCompany: "Add company RU",
      addNewUser: "Add new user RU",
      editUser: "Edit users RU",
      addNewRole: "Add new role RU",
      editRole: "Edit role RU",
      addNewCountry: "Add new country RU",
      editCountry: "Edit country RU",
      addNewCity: "Add new city RU",
      addNewCourier: "Add new courier RU",
      editCourier: "Edit courier RU",
      addNewDistrict: "Add new district RU",
      addNewStatus: "Add new status RU",
      editStatus: "Edit status RU",
      addNewLocation: "Add new location RU",
      editLocation: "Edit location RU",
      addNewPostCode: "Add new post code RU",
      editPostCode: "Edit post code RU",
      addNewMethod: "Add new method RU",
      editPaymentMethod: "Edit payment method RU",
      addNewCategory: "Add new category RU",
      editCategory: "Edit category RU",
      addNewChannel: "Add new channel RU",
      editChannel: "Edit channel RU",
      addNewSubdistrict: "Add new subdistrict RU",
      editSubdistrict: "Edit district RU",
      addNewProvince: "Add new province RU",
      deleteProvince: "Delete province RU",
      editCampaign: "Edit campaign RU",
      editLeadsRedirection: "Edit leads redirection RU",
      addNewSystemVariable: "Add new system variable RU",
      addNewTrafficSource: "Add new traffic source RU",
      addNewCouriersApiMapping: "Add new couriers api mapping RU",
      editCouriersStatus: "Edit couriers status RU",
      editCouriersApiMapping: "Edit couriers api mapping RU",
      addNewCouriersStatuses: "Add new status",
      addNewSystemClassifier: "Add new system classifier RU",
      editSystemClassifier: "Edit system classifier RU",
      deposit: "Deposit RU",
      withdraw: "Withdraw RU",
      transaction: "Transaction RU",
      addNew: "Add new RU",
      edit_news: "Edit news RU",
      sendFeedback: "Send feedback RU",
    },
    modalTooltips: {
      company_members: "Company members",
      addNewUser: "Add new user RU",
      addNewRole: "Add new role RU",
      addNewCountry: "Add new country RU",
      editCountry: "Edit country RU",
      addNewCity: "Add new city RU",
      editCity: "Edit city RU",
      addNewCourier: "Add new courier RU",
      editCourier: "Edit courier RU",
      addNewStatus: "Add new status RU",
      editStatus: "Edit status RU",
      addNewLocation: "Add new location RU",
      editLocation: "Edit location RU",
      addNewPostCode: "Add new post code RU",
      editPostCode: "Edit post code RU",
      addNewMethod: "Add new method RU",
      addNewCategory: "Add new category RU",
      editCategory: "Edit category RU",
      editChannel: "Edit channel RU",
      addNewSubdistrict: "Add new subdistrict RU",
      editSubdistrict: "Edit district RU",
      addNewProvince: "Add new province RU",
      deleteProvince: "Delete province RU",
      addNewChannel: "Add new channel RU",
      addNewSystemVariable: "Add new system variable RU",
      addNewTrafficSource: "Add new traffic source RU",
      addNewCouriersApiMapping: "Add new couriers api mapping RU",
      addNewCouriersStatuses: "Add new status",
      editCouriersApiMapping: "Edit couriers api mapping RU",
      editCouriersStatus: "Edit couriers statuse RU",
      addNewSystemClassifier: "Add new system classifier RU",
      editSystemClassifier: "Edit system classifier RU",
      filters: "Filters RU",
      deposit: "Deposit RU",
      withdraw: "Withdraw RU",
    },
    tables: {
      campainFocusTable: "Таблица фокуса кампании",
    },
    title: {
      edit_account_manager: "Edit account manager",
      createNewInstance: "Создать новый образец",
      createNewClassifier: "Создать новый фильтр",
      editClassifier: "Редактировать фильтр",
      editPartner: "Edit Partner RU",
      editEmployee: "Edit Employee RU",
      editCompany: "Edit company RU",
      addNewPartner: "Add new Partner RU",
      addNewEmployee: "Add new Employee RU",
      addNewCompany: "Add new company RU",
      selectInstance: "Выберите образец",
      selectInstanceAndCampaign: "Выберите образец и кампанию",
      campaignsAllInstances: "Кампании во всех случаях",
      all: "Все RU",
      allArrowAll: "Все -> Все RU",
      allArrow: "Все -> RU",
      arrowAll: "-> Все RU",
      createNewProduct: "Создать новый продукт",
      campaignsActivityStatus: "Изменить статус активности классификатора",
      userActivityStatus: `Change users activity status`,
      filters: "фильтры",
      activityStatus: `Change activity status`,
      addNewPage: "Add new page",
      addNewCampaign: "Add new campaign",
      addNewLeadsRedirection: "Add new leads redirection",
      notes: "Notes",
      notFoundLabel: "Sorry, the page you visited does not exist.",
      addNew: "Add new",
      new: "New",
      change: "Change",
      inProgressTitle: "Page in progress RU",
      inProgressDesc: "Sorry, the page you visited is still in progress. RU",
    },
    columnTitle: {
      to_check: 'To check',
      product_sku: "Product SKU RU",
      product_sku_short: "SKU RU",
      comment: "Comment RU",
      classifier_end: "Classifier end RU",
      phone_origin: "Phone (origin) RU",
      id: "ID",
      type: "Тип",
      country: "Страна",
      country_short: "Страна",
      name: "Имя",
      second_name: "Second name RU",
      short_name: "Short name RU",
      campaign_name: "Название кампании",
      domain: "Домен",
      user: "Пользователь",
      password: "Пароль",
      password_short: "Пароль",
      url: "URL базовой кампании",
      url_short: "URL",
      active: "Статус активности кампании",
      active_short: "Активный",
      classifier_active: "Статус деятельности фильтра",
      classifier_active_short: "Активный",
      online: "Статус кампании онлайн",
      online_short: "Онлайн",
      created: "Дата создания",
      created_short: "Создано",
      updated: "Недавно обновленный",
      updated_short: "Обновлено",
      options: "Опции",
      instance: "Экземпляр",
      focus_instance: "Фокусный образец",
      classifiers_external: "Внешние фильтры",
      affbay_status: "Affbay статус",
      status: "Статус",
      qualified: "Квалифицированный",
      approvedAt: "Approved RU",
      approvedAt_short: "Date approved at RU",
      classifier: "Classifier RU",
      product: "Product RU",
      partner: "Partner RU",
      payout: "Payout RU",
      payout_scheme: "Payout Schema RU",
      payout_scheme_short: "Scheme RU",
      phone: "Phone RU",
      click_id: "click_id RU",
      pub_id: "Pub ID RU",
      lp_url: "Lp URL",
      role: "Role RU",
      description: "Описание",
      application_access: "Application access RU",
      avalable_in_affbay: "Available in Affbay RU",
      avalable_in_affbay_short: "In Affbay RU",
      avalable_for_sale: "Available for sale RU",
      avalable_for_sale_short: "For sale RU",
      base_price: "Base prices RU",
      base_price_short: "Price RU",
      visible_for_partners: "Visible for partners RU",
      visible_for_partners_short: "For partners RU",
      categories: "Categories RU",
      landing: "Landing RU",
      preview: "Preview RU",
      is_visible: "Visible RU",
      is_top: "Top RU",
      is_mobile: "Mobile RU",
      is_desktop: "Desktop RU",
      deleted_at: "Deleted RU",
      accountCounter: "Account counter RU",
      isInternal: "Internal RU",
      partnerToken: "Partner token RU",
      email: "E-mail RU",
      manager: "Manager RU",
      company: "Company RU",
      skype: "Skype",
      telegram: "Telegram",
      is_active_short: "Active RU",
      key: "Key RU",
      focusId: "Focus ID RU",
      is_operative: "Operative RU",
      address: "Address RU",
      code: "Code RU",
      currency: "Currency RU",
      is_active: "Active RU",
      name_eng: "Name (eng) RU",
      bank_transfer: "Bank transfer RU",
      city: "City RU",
      province: "Province RU",
      district: "District RU",
      subdistrict: "Subdistrict RU",
      postCode: "Post code RU",
      courier: "Courier RU",
      lead_time: "Lead time RU",
      lead_time_text: "Lead time text RU",
      district_code: "District code RU",
      island: "Island RU",
      deliver_cost: "Deliver cost RU",
      resturn_cost: "Return cost RU",
      insurance_fee: "Insurance fee RU",
      code_fee: "Code fee RU",
      admin_fee: "Admin fee RU",
      dalivery_cost: "Delivery cost RU",
      permission_id: "Permission ID RU",
      is_qualified: "Qualified RU",
      is_contacted: "Contacted RU",
      is_qualified_short: "Qualified RU",
      value: "Value RU",
      courier_name: "Courier name RU",
      courier_name_short: "Courier RU",
      courier_status: "Courier status RU",
      status_description: "Status description RU",
      notes: "Notes RU",
      external: "External RU",
      additional_inf: "Additional informations RU",
      additional_inf_short: "Informations RU",
      view: "View RU",
      is_manual_short: "Manual RU",
      is_manual: "Is Manual RU",
      is_successful_short: "Successful RU",
      is_successful: "Is Successful RU",
      response_time_short: "Time RU",
      response_time: "Response time RU",
      response_status_short: "Status RU",
      response_status: "Response Status RU",
      response_text_short: "Text RU",
      response_text: "Response Text RU",
      title: "Title RU",
      is_home_page: "Visible on dashboard RU",
      is_home_page_short: "On dashboard RU",
      news_type: "News type RU",
      visible_on_home_page: "Visible on dashboard RU",
      date_from: "Start form RU",
      date_from_short: "Start RU",
      date_to: "End form RU",
      date_to_short: "End RU",
      scheme: "Scheme RU",
      leadId: "Lead ID RU",
      accepted: "Accepted RU",
      date: "Date RU",
      params: "Params RU",
    },
    tooltips: {
      company_members: "Company members",
      hide_postbacks: "Hide postbacks RU",
      show_postbacks: "Show postbacks RU",
      copyKey: "Копировать ключ",
      editPage: "Edit page RU",
      deletePage: "Delete page RU",
      cantDeleteAdminSuperAdmin: `Can't delete Admin/Super admin Ru`,
      userActivityStatus: "Change users activity status RU",
      deleteUser: "Delete user RU",
      changeRoleActiveStatus: "Change roles sctive status RU",
      editRole: "Edit role RU",
      deleteRole: "Delete role RU",
      changeActiveStatus: "Change active status RU",
      viewCampaign: "View campaign RU",
      changeQualifiedStatus: "Change qualified status RU",
      deleteStatus: "Delete status RU",
      deletePostCode: "Delete post code RU",
      deleteCstegory: "Delete category RU",
      change_activity_status: `Change activity status Ru`,
      delete_leads_direction: "Delete direction RU",
      edit_leads_direction: "Edit leads redirection RU",
      delete: "Delete RU",
      edit: "Edit RU",
      accept: "Accept RU",
      reject: "Reject RU",
    },
    statement: {
      unrecognized_error: "Unrecognized error",
      refresh_page: "Refresh page RU",
      your_account_has_been_created: "Your account has been activated RU",
      your_account_has_been_created_message:
        "We will let you know in 24 hours. RU",
      sorry_we_could_not_get_your_data: `Sorry, we couldn't get your data RU`,
      databaseConnectionError:
        "Что-то пошло не так при подключении к базе данных!",
      databaseConnectionSuccess: "Успешная загрузка с базы данных!",
      instanceCreated: "Успешное добавление образца!",
      classifierCreated: "Фильтр создан!",
      classifierUpdated: "Фильтр обновлен!",
      thePropsAreTheSame: "Значения в полях не изменились",
      campaignUpdated: "Успешное обновление кампании!",
      qulifiedUpdate: "Успешная смена фильтра",
      app_user_updated: "App user updated! RU",
      app_user_created: "App user created! RU",
      status_updated: "Status updated! RU",
      there_was_a_problem_removing_app_user:
        "There was a problem removing app user RU",
      app_user_deleted: "App user deleted RU",
      there_was_a_problem_removing_role: "There was a problem removing role RU",
      there_was_a_problem_removing_permission:
        "There was a problem removing permission RU",
      there_was_a_problem_creating_role: "There was a problem creating role RU",
      user_role_updated: "User role updated! RU",
      companie_created: "Companie created! RU",
      companie_updated: "Companie updated! RU",
      there_was_a_problem_removing_city: "There was a problem removing city RU",
      city_deleted: "City deleted! RU",
      city_created: "City created! RU",
      city_updated: "City updated! RU",
      there_was_a_problem_removing_country:
        "There was a problem removing country RU",
      country_created: "Country deleted! RU",
      country_updated: "Companie updated! RU",
      there_was_a_problem_removing_courier:
        "There was a problem removing courier RU",
      courier_deleted: "Courier deleted! RU",
      courier_updated: "Courier updated! RU",
      courier_created: "Courier created! RU",
      there_was_a_problem_removing_district:
        "There was a problem removing district RU",
      district_deleted: "District deleted! RU",
      district_updated: "District updated! RU",
      district_created: "Dictrict created! RU",
      there_was_a_problem_removing_location:
        "There was a problem removing location RU",
      location_deleted: "Location deleted! RU",
      location_updated: "Location updated! RU",
      location_created: "Location created! RU",
      there_was_a_problem_removing_payment_method:
        "There was a problem removing payment method RU",
      payment_method_deleted: "Payment method deleted! RU",
      payment_method_updated: "Payment method updated! RU",
      payment_method_created: "Payment method created! RU",
      there_was_a_problem_removing_post_code:
        "There was a problem removing post code RU",
      post_code_deleted: "Post code deleted! RU",
      post_code_updated: "Post code updated! RU",
      post_code_created: "Post code created! RU",
      there_was_a_problem_removing_payment_category:
        "There was a problem removing payment category RU",
      payment_category_deleted: "Payment category deleted! RU",
      payment_category_updated: "Payment category updated! RU",
      payment_category_created: "Payment category created! RU",
      there_was_a_problem_removing_product_category:
        "There was a problem removing product category RU",
      product_category_deleted: "Product category deleted! RU",
      product_category_updated: "Product category updated! RU",
      product_category_created: "Product category created! RU",
      there_was_a_problem_removing_province:
        "There was a problem removing province RU",
      province_deleted: "Province deleted! RU",
      province_updated: "Province updated! RU",
      province_created: "Province created! RU",
      there_was_a_problem_removing_sales_channel:
        "There was a problem removing sales channel RU",
      sales_channel_deleted: "Sales channel deleted! RU",
      sales_channel_updated: "Sales channel updated! RU",
      sales_channel_created: "Sales channel created! RU",
      there_was_a_problem_removing_status:
        "There was a problem removing status RU",
      status_deleted: "Status deleted! RU",
      status_created: "Status created! RU",
      there_was_a_problem_removing_subdistrict:
        "There was a problem removing subdistrict RU",
      subdistrict_deleted: "Subdistrict deleted! RU",
      subdistrict_updated: "Subdistrict updated! RU",
      subdistrict_created: "Subdistrict created! RU",
      employee_updated: "Employee updated! RU",
      employee_created: "Employee created! RU",
      filters_confirmed: "Filtry potwierdzone RU",
      filters_have_been_reset: "filters have been reset RU",
      there_was_a_problem_removing_page: "There was a problem removing page RU",
      page_deleted: "Page deleted! RU",
      page_updated: "Page updated! RU",
      page_created: "Page created! RU",
      partner_updated: "partner updated! RU",
      partner_created: "partner created! RU",
      change_permission_status: "Change permissions status RU",
      there_was_a_problem_removing_campaign:
        "There was a problem removing campaign RU",
      there_was_a_problem_creating_campaign:
        "There was a problem creating campaign RU",
      campaign_deleted: "Campaign deleted! RU",
      campaign_updated: "Campaign updated! RU",
      campaign_created: "Campaign created! RU",
      role_deleted: "Role deleted! RU",
      choose_out_of_scope: "Choose out of the scope! RU",
      date_changed: "Date changed RU",
      session_expired: "Session expired RU",
      session_expired_description:
        "Your session has expired. Please login again to continue working RU",
      incorrect_login_information:
        "Your login information was incorrect. Please check and try again RU",
      there_was_a_problem_removing_system_variable:
        "There was a problem removing system variable RU",
      system_variable_deleted: "System variable deleted! RU",
      system_variable_updated: "System variable updated! RU",
      system_variable_created: "System variable created! RU",
      there_was_a_problem_removing_couriers_api_mapping:
        "There was a problem removing couriers api mapping RU",
      couriers_api_mapping_deleted: "Couriers api mapping deleted! RU",
      couriers_api_mapping_updated: "Couriers api mapping updated! RU",
      couriers_api_mapping_created: "Couriers api mapping created! RU",
      system_classifier_created: "System classifierl created! RU",
      system_classifier_updated: "System classifierl updated! RU",
      updated: "Updated! RU",
      created: "Created! RU",
      deleted: "Deleted! RU",
      success: "Success! RU",
      accepted: "Accepted! RU",
      rejected: "Rejected RU",
      noCompanyIdAssigned:
        "Your account has no company assigned. Please contact us through support panel. RU",
      noDataToDisplay: "Sorry, there is no data to display RU",
    },
    tabs: {
      createNewProduct: "Создать новый продукт",
      pricing: "Цена",
      conversion: "Конверсия",
      affbayVisibility: "affbay видимость",
      landings: "Landings / Prelandings RU",
    },
    navigationItems: {
      dashboard: "Дашборд",
      statistics: "Статистика",
      leads: "Лиды",
      employees: "Employees RU",
      products: "Продукты",
      pages: "Pages RU",
      landings: "Лендинги",
      companies: "Companies RU",
      partners: "Partners RU",
      campaigns_partner: "Campaigns RU",
      campaigns_admin: "Campaigns admin RU",
      finance: "Finance RU",
      financeAdmin: "Finance admin RU",
      tools: "Инструменты",
      news: "Новости",
      news_admin: "Новости admin",
      support: "Поддержка",
      faq: "FAQ",
      contact: "Контакт",
      login: "Логин",
      register: "Регистрация",
      offers: "Офферы",
      create_new_order: "Create new order RU",
      orders: "Orders RU",
      couriersStatusMapping: "Status mapping RU",
      account_managers: "Account Managers RU",
    },
    subNavigationItem: {
      k8_services: "K8 Services",
      sales: "Sales RU",
      sales_p2: "P2 RU",
      sales_resale: "P3 RU",
      sales_winback: "Winback RU",
      administration: "Administration RU",
      appRemarketing: "Remarketing - P2",
      appUsers: "Users RU",
      appUsersRole: "Users role RU",
      webSocket: "WebSocket RU",
      appUsersPermissions: "Users permissions RU",
      // Call center
      callCenter: "Кол-центр",
      cc_instances: "Образцы",
      cc_campaigns: "Кампании",
      cc_classifiers: "Фильтры",
      // li_classifiers_cc: 'Affbay фильтры',
      li_classifiers_cc: "Mapping фильтры", // TODO: Dodać tłumaczenie
      cc_webhooks: "Webhooks RU",
      cc_leads_direction: "Leads redirection RU",
      // Settings
      setting: "Настройка",
      api: "API",
      payment: "Платеж",
      postbacks: "Постбэки",
      referralHistory: "Рефералы",
      security: "Безопасность",
      settings: "Настройки",
      // Dictionaries
      dictionaries: "Dictionaries RU",
      countries: "Countries RU",
      cities: "Cities RU",
      couriers: "Couriers RU",
      districts: "Districts RU",
      subdistricts: "Subdistricts RU",
      statuses: "Statuses RU",
      locations: "Locations RU",
      postCodes: "Post cods RU",
      paymentMethods: "Payment methods RU",
      productCategories: "Product cat. RU",
      salesChannels: "Sales channels RU",
      provinces: "Provinces RU",
      system_variables: "System variables RU",
      traffic_sources: "Traffic sources RU",
      couriers_api_mapping: "Couriers mapping RU",
      couriers_statuses: "Couriers statuses RU",
      system_classifiers: "CC system classifiers dictionary RU",
    },
    others: {
      of: "из",
      rows: "rows RU",
    },
    contentTitle: {
      support: "Поддержка",
    },
    contentText: {
      alreadyHaveAnAccount: "У вас уже есть аккаунт?",
      iDontHaveAnAccount: "У меня нет аккаунта.",
      rememberThePassword: "Вспомнили пароль?",
      support:
        "Если вам нужна помощь с аккаунтом, появились вопросы об использовании платформой или появились технические проблемы, напишите нам.",
    },
    links: {
      all_news: "All news RU",
      login: "Войти",
      registration: "Регистрация",
      forgotYourPassword: "Забыли пароль?",
    },
    placeholders: {
      enterName: "Введите имя",
      enterEmail: "Введите e-mail",
      enterPassword: "Введите пароль",
      repeatPassword: "Введите пароль",
      selectCountry: "Select country RU",
      notRequired: "Не обязательно",
      select: "Select... RU",
      globalSearch: "Global search RU",
      search: "Search RU",
      findProduct: "Find product RU",
      choose_affbay_status: "Choose affbay status RU",
      choose_country: "Choose country RU",
      choose_company: "Choose company RU",
      choose_product: "Choose product RU",
      choose_partner: "Choose partner RU",
      choose_scheme: "Choose scheme RU",
      choose_classifier: "Choose classifier RU",
      choose_remarketing_campaign: "Choose campaign RU",
      enter_min_4_letters: "Entere min 4 letters RU",
    },
    pageTitle: {
      k8_services: "K8 Services",
      dashboard: "Дашборд",
      account_managers: "Account managers",
      loginContent: "Войти",
      registerContent: "Регистрация",
      resetPasswordContent: "Восстановить пароль",
      employees: "All employees PL + All employees Call center",
      products: "Products",
      pages: "Pages",
      companies: "Companies",
      addNewCompany: "Add company",
      partners: "Partners",
      administrationUsers: "Administration - Users",
      administrationUsersRole: "Administration - Users Role",
      instances: "Instances",
      campaigns: "Campaigns",
      classifiers: "Classifiers",
      mappingClassifiers: "Mapping classifiers",
      webhooks: "Webhooks",
      countriesDictionary: "Countries dictionary",
      citiesDictionary: "Cities dictionary",
      couriersDictionary: "Couriers dictionary",
      districtsDictionary: "Districts dictionary",
      statusesDictionary: "Statuses dictionary",
      locationsDictionary: "Locations dictionary",
      postCodeDictionary: "Post codes dictionary",
      paymentMethodDictionary: "Payment methods dictionary",
      productCategoriesDictionary: "Product categories dictionary",
      salesChannels: "Sales channels dictionary",
      subdistrictsDictionary: "Subdistricts dictionary",
      provincesDictionary: "Provinces dictionary",
      campaigns_admin: "Campaigns - Admin",
      orders: "Orders",
      leads_directions: "Leads redirections",
      system_variables: "System variables",
      traffic_sources: "Traffic sources",
      couriers_api_mapping: "Couriers api mapping",
      system_classifiers: "System classifiers",
      finance: "Finance Ru",
      news_admin: "News Admin",
      finance_admin_view: "Finance admin view RU",
      resaleP3: "P3 Resale RU",
      resaleP2: "P2 Resale RU",
    },
    productsFormMain: {
      editProduct: "Edit product",
      createNewProduct: "Create new product",
      englishName: "English name",
      currency: "Currency",
      callCenter: "Call Center",
      sku: "SKU",
      originalName: "Original name",
      shortName: "Short name",
      printName: "Print name",
      categories: "Categories",
      availableInAffbay: "Available in AFFBAY",
      testProduct: "Test product",
      availableForSale: "Available for sale",
      active: "Active",
      country: "Country",
      sales: "Sales",
      mainCamapignId: "Main campaign id",
      resellCampaignId: "Resell campaign id",
      productionCost: "Production cost (USD)",
      productionCostLocalCurrency: "Production cost (local currency)",
      affbayVisibility: "Affaby Visibility",
      visibleForPartners: "Visible for partners",
      visibleOnHomePage: "Visible on home page (top offers)",
      isRestricted: "Is restricted",
      defaultGlobalPayout: "Default global payout (USD)",
      defaultPayoutScheme: "Default payout schema",
      cpa: "CPA",
      cpl: "CPL",
      landingPriceForConsumer: "Landing price for consumer",
      approveVisibleOnPage: "Approve visible on page (%)",
      payoutVisibleOnPage: "Payout visible on page (USD)",
      descriptionEN: "Description EN",
      descriptionRU: "Description RU",
      traffic_sources_en: "Traffic sources (description) EN",
      traffic_sources_ru: "Traffic sources (description) RU",
      terms_en: "Terms EN",
      terms_ru: "Terms RU",
      conditions_en: "Conditions EN",
      conditions_ru: "Conditions RU",
      productsWillNotBeAvailableInAffbay:
        "Products will not be available in AFFBAY",
      updateCampaigns: "Refresh campaings list",
      save: "Save",
      create: "Create",
      reset: "Reset",
    },
    checkBoxes: {
      iWantToReceiveImportantNewsByEmail:
        "Я хочу получать важные новости на email (рекомендуется)",
      iWantToReceivePromotionslNewsByEmail:
        "Я хочу получать рекламные новости на email",
      iWantToReceiveInformationAboutChangesInOffersByEmail:
        "Я хочу получать информацию об изменениях офферов на email",
      iWantToReceiveOffersOfOffersByEmail:
        "Я хочу получить предложения офферов на email",
      add_to_affbay_classifiers_library: "Add to Affbay Classifiers library",
    },
    landingPage: {
      about: "О нас",
      topOffers: "Топ офферы",
      contact: "Контакт",
      logIn: "Войти",
      certifiedProducts: "СЕРТИФИЦИРОВАННЫЕ ПРОДУКТЫ ОТ ПРЯМОГО РЕКЛАМОДАТЕЛЯ",
      afiliateNetwork: "Прямой рекламодатель на рынках",
      saudiArabAndIndonesia: "Азии и арабских стран",
      becomeAPartner: "Стать партнером",
      activePartners: "Активных партнеров",
      offersInAsia: "Офферов на Азию",
      yearsOnTheMarket: "Года на рынке",
      countries: "Открытых гео",
      aboutText:
        "Affbay – прямой рекламодатель на рынках Азии и Арабских странах. Мы не ресейлим оффера, все продукты наши собственные. У нас вы найдете десятки предложений на тему красоты, здоровья и похудения с выплатами от 18$ выше за подтвержденную заявку. Работаем по моделям CPA, CPL, CPI.",
      regularPayouts: "Регулярные выплаты",
      regularPayoutsText:
        "Выплаты средств без задержек на большинство платежных систем.",
      highPayouts: "Высокие ставки",
      highPayoutsText: "От 18$ и выше за подтвержденную заявку.",
      ownCallCenter: "Собственный колл-центр",
      ownCallCenterText:
        "Опытные специалисты КЦ выполняющие работу эффективно.",
      personalManager: "Персональный менеджер",
      personalManagerText:
        "Отзывчивый саппорт поможет вам решить любые проблемы.",
      weDontHaveTopOffers: "На данный момент у нас нет топ офферов",
      howCanWeHelpYou: "Как мы можем вам помочь?",
      writeUs: "Напишите нам о своих проблемах.",
      becomeOurPartner:
        "Станьте нашим партнером и начните зарабатывать прямо сейчас",
      affbayAsia: " Affbay.asia",
      ownedBy: "Owned By: Izabella Frolushkin FZE",
      privacyPolicy: "Политика конфиденциальности",
      termsAndConditions: "условия и положения",
      send: "Отправить",
      name: "Имя",
      email: "E-mail",
      object: "Тема",
      message: "Сообщение",
      thankYou: "Спасибо",
      youWillBeHearing:
        "Your message was sent. We will handle your issue with 48 hours.",
      startEarning: "Начать зарабатывать",
      sitePrice: "Цена на сайте",
      approve: "Апрув",
      payouts: "Выплаты",
      country: "Страна",
    },
    loginPage: {
      logIn: "Войти",
      registration: "Регистрация",
      email: "E-mail",
      password: "Пароль",
      forgotYourPassword: "Забыли пароль?",
      logIntoThePlatform: "Войти в платформу",
      iDontHaveAnAccount: "У меня нет аккаунта.",
      send_request_again: "Send request again RU",
    },
    registrationPage: {
      registration: "Регистрация",
      name: "Имя",
      email: "E-mail",
      password: "Пароль",
      repeatPassword: "Повторите пароль",
      country: "Страна",
      skype: "Skype",
      telegram: "Telegram",
      notRequired: "Не обязательно",
      iAgreeToAffbayAsia: "Я согласен с",
      privacyPolicy: "Политикой конфиденциальности",
      and: "и",
      termsAndConditions: "условия и положения",
      nextStep: "Следующий шаг",
      alreadyHaveAnAcount: "У вас уже есть аккаунт?",
      logIn: "Войти",
      thxForReg: "Thank you for registration",
      toStartWork: "To start work please provide us few information",
      surveyIsNotAvailable: "Survey is not available",
      thisAccountIsA: "Этот аккаунт...",
      whichgeos: "С какими гео работали ранее?",
      whatgeo: "На какие гео хотите лить?",
      whatTypeOfTraffic: "Какой тип трафика вы используете?",
      whatBugdets: "С какими бюджетами вы работали?",
      whatDoYouPrefer: "Что вы предпочитаете – S2S или API?",
      submitMyApplication: "Подать заявку",
      enterYourName: "Введите имя",
      enterEmail: "Введите e-mail",
      enterPassword: "Введите пароль",
    },
    submitSuccess: {
      congrats: "Поздравляем, ваш аккаунт создан!",
      nowYouCan: "Теперь вы можете войти в свой аккаунт",
      logIn: "Войти",
    },
    resetPassword: {
      password_changed: "Your password has been changed. You can login now. RU",
      typeTheAdress:
        "Введите e-mail вашей учетной записи, и мы вышлем вам инструкции по сбросу пароля. Также проверьте папку со спамом.",
      email: "E-mail",
      set_new_password: "Set new password RU",
      remember: "Вспомнили пароль?",
      logIn: "Войти",
      checkEmail: "Проверьте ваш e-mail",
      withinAFew:
        "Within a few minutes you will receive an email. In this message we will send you a link to set new password. RU",
      new_password: "New password RU",
    },
    supportView: {
      support: "Support RU",
      ifYouNeedHelp:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us. RU",
      subject: "Subject RU",
      message: "Message RU",
      send: "Send RU",
    },
    newsView: {
      news: "News RU",
      allNews: "All news RU",
    },
    offersView: {
      offers: "Офферы",
      offersFound: "офферов найдено",
      show: "Показать:",
      sortBy: "Сортировать по:",
      category: "Категория",
      conversionType: "Тип конверсии",
      sort: "Сортировать",
      accessRequest: "Запросить доступ",
      wirteUs:
        "Расскажите нам об источниках трафика, с которыми вы работаете и вашем опыте.",
      send: "Отправить",
      thankYou: "Спасибо",
      youWillBeHearing: "Мы с вам свяжемся в ближайшее время!",
      sitePrice: "Цена на сайте",
      payouts: "Выплаты",
      country: "Страна",
      originalCountry: "Original country RU",
      readMore: "Узнать больше",
      exampleCodePhp: "Скачать пример",
    },
    offersDetails: {
      sku: "SKU",
      // tabs
      description: "Описание",
      targetURL: "Target URL",
      postbacks: "Постбэки",
      trafficSources: "Источники трафика",
      news: "Новости",
      terms_and_conditions: "Terms & conditions RU",
      // product card
      payout: "Выплаты",
      sitePrice: "Цена на сайте",
      category: "Категория",
      country: "Страна",
      devices: "Устройства",
      landings_tab: "Лендинги",
      // target url
      step_1:
        "Step 1. Choose landing page and prelanding. You can choose multiple items which be opened randomly in your campaign",
      step_2:
        "Step 2. Enter new name for your campaign and click Generere URL button to save it.",
      landings: "Лендинги",
      prelandings: "Прелендинги",
      campaign_name: "Название кампании",
      generateURL: "Генерировать URL",
      allProductCampaigns: "Мои кампании",
      name: "Название",
      prelanding: "Прелендинг",
      landing: "Лендинг",
      URL: "URL",
      youHaveTo: "You have to choose LP and type Campaign name RU",
      noLandings: "Нет страниц для отображения",
      // postbacks
      urlForSales: "Постбэк URL для Sales",
      urlForHolds: "Постбэк URL для Holds",
      urlForRejects: "Постбэк URL для Rejects",
      urlForTrash: "Постбэк URL для Trash",
      urlForDuplicated: "Postback URL for Duplicated RU",
      urlForExcluded: "Postback URL for Excluded RU",
      urlForValidated: "Postback URL for Validated",
      urlForCheck: "Postback URL for Check",
      googleTrackingID: "Google tracking ID RU",
      facebookPixelID: "Facebook pixel ID RU",
      customPixelID: "Custom pixel ID RU",
      useGlobalPostbacks: "Использовать глобальные постбэки",
      createPostback: "Создать постбэк",
      updatePostback: "Update postback RU",
      fillInTheData: "Заполните данные ниже, чтобы создать новый постбэк.",
      protocol: "Протокол",
      domain: "Домен",
      exampleCOM: "example.com",
      extraPathParameter: "Доп. параметр пути",
      folderSlash: "/folder/subfolder",
      parameter: "Параметр",
      addNew: "Добавить",
      postbackURLPreview: "Предварительный просмотр URL",
      save: "Сохранить",
      basic: "Basic RU",
      advanced: "Advanced RU",
      postbackSavedSuccessfully: "Postback saved successfully RU",
      postbackSavedWithWarning: "Postback saved with warning RU",
      postbackNotSaved: "Unable to saved postback RU",
      clearPostback: "Clear Postback RU",
    },
    profileView: {
      personlaData: "Персональные данные",
      changePassword: "Изменить пароль",
      notifications: "Уведомления",
      apitoken: "API Token",
      // personal data
      email: "E-mail",
      name: "Имя",
      phone: "Телефон",
      telegram: "Telegram",
      skype: "Skype",
      timeZone: "Временная зона",
      apply: "Применить",
      cancel: "Отменить",
      id_referral: "Registration link RU",
      // change password
      currentPass: "Текущий пароль",
      newPass: "Новый пароль",
      repeatNewPass: "Повторите новый пароль",
      change: "Изменить",
      // notifications
      importantNews: "Я хочу получать важные новости на email (рекомендуется)",
      promotionalNews: "Я хочу получать рекламные новости на email",
      information: "Я хочу получать информацию об изменениях офферов на email",
      offers: "Я хочу получить предложения офферов на email",
      customVariablesList: "Custom Variables List RU",
      postbacksList: "Postbacks List RU",
    },
    toolsView: {
      tools: "Инструменты",
      // fa
      faq: "Часто задаваемые вопросы",
      answersToFAQ:
        "Ответы на часто задаваемые вопросы относительно выплат их реализации и Affbay в общем.",
      readMore: "Узнать больше",
      // API Documentatio
      apiDocs: "API документация",
      leadSendExample: "Пример формы отправки лида",
      readOurDocs:
        "Прочитайте нашу документацию, для получения ответов на многие вопросы, относительно API.",
      readDocs: "Читать документацию",
      // postback documentatio
      postbackDocs: "Постбэк документация",
      postbacksNotify:
        "Постбэки уведомляют о совершенных целевых действиях на продающих страницах.",
      // integration archi mode
      integrationArchiModel: "Модель интеграции архитектуры",
      hardToUnderstand:
        "Трудно понять, как работает Affbay? Прочитайте об нескольких примерах архитектуры.",
      // number validatio
      numberValidation: "Проверка номера",
      checkingTheNumber:
        "Проверка номера является ключом к повышению качества привлечения клиентов и доходов.",
      longpageFromWidget: "Longpage form widget RU",
      longpageFromWidgetDescription:
        "Include our form into your webpage. Simply paste our code, add your token and sku to start generating new leads. RU",
    },
    select: {
      select_timezone: "Select timezone RU",
      select_status: "Select status RU",
      select_available: "Select available RU",
      select: "Select RU",
      select_country: "Select country RU",
    },
    spin: {
      loading: "Loading... RU",
    },
    faq: {
      header_1: "What is Affbay Asia? RU",
      header_2: "What kinds of integration do we support? RU",
      header_3: "Do you need your own tracker to integrate by S2S? RU",
      header_4:
        "Which parameters are supported by Affbay (S2S integration)? RU",
      header_5: "How to add parameters you want to receive? RU",
      header_6: "Which parameter is Affbay’s? RU",
      header_7: "How to configure general postbacks for S2S? RU",
      header_8: "What is postback for sales, holds, rejected? RU",
      header_9: "How to configure getting postback for concrete offer? RU",
      header_10: "How to add payout to postback url? RU",
      header_11: "How should a postback url look like? RU",
      content_1:
        "We’re direct advertisers in Indonesia and Thailand, we have our own high conversion COD offers. More geos in Asia coming soon! RU",
      content_2:
        "We can integrate with you by S2S or by API. Documentation for API is provided on our website in section Offers (at the top of webpage). Choose offers you like and click Details. RU",
      content_3:
        "Affbay is a user friendly platform, which provides you with your personal account with dashboard and requires no tracker, although we can provide you S2S postback integration with your trackers. RU",
      content_4:
        'You can add any parameter that is supported by your tracker. See "How to add parameters you want to receive?". RU',
      content_5:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Just add any parameter without brackets {} () and put it also in your postback url. RU",
      content_6: [
        "Our built in parameters are: RU",
        "click_id - global identifier (UUID) of a specific lead RU",
        "campaign_id - global identifier (UUID) of a specific campaign RU",
        "aff_id - global identifier (UUID) of a specific user (you). RU",
        "If you specify a custom variable named as one of the above, postback will use it, substituting your tracker data instead of our default data. We discourage this to avoid confusion. RU",
      ],
      content_7:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Here you can add you general postback url. If you want to receive postbacks for sales – just put your postback url in this field, the same for postback for holds and rejected. Also you can add Facebook Pixel and Google Tracking ID for all the offers. RU",
      content_8:
        "If you want to receive postback for approved leads – choose postback for sales. Postback for holds means getting postback for every lead that came into our system. Postback for rejected means getting postback for leads that were rejected – invalid phone number or leads not being reached by Call Center in a month. RU",
      content_9:
        "Enter section Offers (at the top of webpage) -> choose offers and click Details -> there you can add postback urls and also Facebook Pixel and Google Tracking ID for specific offer. RU",
      content_10: "&amount=13&payout_type=pps¤cy=USD",
      content_11:
        "https://callbacks.bitterstrawberry.org/?token=0c4c1249825285a2997bacf09c09ec28&hash={hash}&transaction_id={click_id}&amount=13&payout_type=pps¤cy=USD",
    },
    news_type: {
      all_news: "All news RU",
      new: "New RU",
      important: "Important RU",
      info: "Info RU",
    },
    setting_page: {
      click_id: "Click ID RU",
      check_click_id: "Check click ID doubles RU",
      click_id_days: "Number of days to check RU",
    },
  },
  pl_PL: {
    validation: {
      current_password_is_incorrect: "Aktualne hasło jest nieprawidłowe",

      nameIsRequired: "imie jest wymagane",

      lastNameIsRequired: "Nazwisko jest wymagane",

      emailIsRequired: "Email jest wymagany",
      incorrectEmailAdres: "Niepoprawny adres e-mail",
      requiredUtf8Characters: "Wymagane znaki UTF-8",

      passwordIsRequired: "Wymagane jest hasło",
      confirmPasswordIsRequired: "Potwierdź hasło jest wymagane",
      requiredTheSamePasswords: "Wymagane te same hasła",
      passwordCondition:
        "Hasło musi zawierać co najmniej 1 literę górną, 1 dolną literę, 1 znak numeryczny, co najmniej 2 znaki specjalne i musi zawierać co najmniej 8 znaków i maksymalnie 32 znaki.",

      skypeIdIsRequired: "Identyfikator Skype jest wymagany",

      telegramIsRequired: "Telegram jest wymagany",

      countryIsRequired: "Kraj jest wymagany",

      requiredMin3Characters: "Wymagane min 3 znaki",
      requiredMin8Characters: "Wymagane min 8 znaków",
      requiredMax15Characters: "Wymagane max 15 znaków",
      requiredMax20Characters: "Wymagane max 20 znaków",
      requiredMax30Characters: "Wymagane max 30 znaków",
      requiredMax50Characters: "Wymagane max 50 znaków",
      requiredMax191Characters: "Wymagane max 191 znaków",
      requiredMax250Characters: "Wymagane max 250 znaków",
      requiredMax255Characters: "Wymagane max 255 znaków",
      requiredAlphaNumeric: "Wymagane alfanumeryczne",
      statusIsRequired: "Wymagany jest status (numer)",
      requiredMax11Numbers: "Wymagane max 11 liczb",
      required: "To pole jest wymagane",
      invalidDomain: "Domena nieprawidłowa",
      duplicatedParam: "Duplikowana nazwa parametrów",
      invalidParamName: "Niepoprawna nazwa parametrów",
      invalidParamValue: "Niepoprawna wartość parametrów",
      invalidUrl: "nieprawidłowy URL",
    },
    statistics: {
      to_check: 'To Check',
      toCheckDescription: 'Leads which were marked as Doubles for the last 30 days.',
      totalDescription:
        "Sprawdzone, wysyłanie do call center, oczekujące, odrzucone, wykluczone i sprzedane",
      awaitingDescription:
        "Klasyfikacja tocząca się (zwana leadami przez call center)",
      rejectedDescription: "Zadzwonił, ale odrzucony",
      soldDescription: "Zweryfikowana sprzedaż",
      orderCreatedDescription: "oczekiwanie na weryfikację",
      trashDescription: "Niewłaściwe liczby itp.",
      excludedDescription: "Podwójne i nie kontaktowane",
      validatedDescription:
        "Sprawdzone przez system, ale jeszcze nie wysłane do call center",
      sendToCcDescription: "Wysłane do call center, ale jeszcze nie zadzwonił",
      doubledDescription: "Oznaczone systemem",
      verifiedApprovrdRateDescription:
        "((Zamówienie utworzone + sprzedane) / ogółem) * 100",
      approvedRateDescription: "(Sprzedany / ogółem) * 100",
      leads: "Leady / Rekordy",
      total: "Łącznie",
      awaiting: "Oczekujące",
      rejected: "Odrzucony",
      approved: "Zatwierdzony",
      approveRate: "Zatwierdzić stawkę",
      payoutAmount: "wysokość wypłaty",
      sold: "Sprzedany",
      orderCreated: "Zamówienie utworzone",
      tocheck: 'Na verificação',
      trash: "Śmieci",
      excluded: "Wykluczone",
      validated: "Zatwierdzone",
      sendToCC: "Wyślij do call center",
      sentcc: "Wysłane do CC",
      double: "Podwójnie",
      verifiedApprovrdRate: "Sprzedaż (potencjalna)",
      approvedRate: "Sprzedaż zweryfikowana",
      unrecognized: "Nierozpoznany",
      noMoney: "Brak pieniędzy",
      noMoneyDescription: "Brak pieniędzy (opis)",
      awaitingAll: "Wszystkie oczekujące",
      awaitingAllDescription:
        "Nowy lead/rekord, zatwierdzony, Oczekujący, wysłany do CC",
      title: "Statystyki leadów/rekordów",
      summary: "Statystyki podsumowujące",
      numberOfLeadsByOffer: "Liczba potencjalnych klientów według oferty",
      percentageOfApprovedLeads:
        "Procent zatwierdzonych potencjalnych klientów",
      callCenterTitle: "Klasyfikatory call center",
    },
    tabPane: {
      details: "Detale",
      summary: "Streszczenie",
      personalData: "Dane osobiste",
      changePassword: "Zmień hasło",
      notifications: "Powiadomienia",
      apiToken: "Token API",
      globalPostbacks: "Globalne postbacki",
      registrationLink: "Link rejestracyjny",
    },
    buttons: {
      yes: "Tak",
      no: "NIE",
      confirm: "Potwierdź",
      today: "Dzisiaj",
      yesterday: "Wczoraj",
      thisMonth: "Ten miesiąc",
      lastMonth: "W zeszłym miesiącu",
      last30Days: "Ostatnie 30 dni",
      resetFilters: "Wyczyść filtry",
      addFilter: "Dodaj filtr",
      removeFilter: "Usuń filtr",
      clearAll: "Wyczyść wszystko",
      submit: "Potwierdzić",
      search: "Szukaj",
      cancel: "Anulować",
      refreshTable: "Odśwież tabele",
      updateCampaign: "Aktualizacja kampanii",
      reset: "Resetowanie",
      register: "Zarejestrój",
      create: "Utwórz",
      login: "Zaloguj sie",
      save: "Zapisz",
      save_new_password: "Zapisz nowe hasło",
      send: "Wyślij",
      apply: "Zastosuj",
      generateToken: "Wygeneruj token",
      addNewEmployee: "Dodaj nowego pracownika",
      addNewProduct: "Dodaj nowy produkt",
      add_new: "Dodaj nowe",
      filter: "Filtr",
      addVariable: "Dodaj zmienną",
      goToMainPage: "Przejdź do strony głównej",
      download: "Pobierz",
      exportXlsx: "Eksportuj XLSX",
      downloading: "Pobieranie",
      new: "Nowy",
      switchToUser: "Przełącz na",
      backToAdminAccount: "Powrót do administratora",
      setPostbackForAllProducts: "Ustaw te postbacki dla wszystkich produktów",
      show: "Pokaż",
      hide: "Ukryj",
      enable: "Włączać",
      disable: "Wyłączyć",
      upload: "Wgrywać",
    },
    radioButtons: {
      leadsCreatedDate: "Lead created at date",
      leadsApprovedDate: "Lead approved at date",
      leadsSoldDate: "Lead sold at date",
      leadsCallCenterLastCallDate: "Lead call center last call date",
      salesCountryTimezone: "Sales country trimezone",
      myTimezone: "My timezone",
    },
    labels: {
      visibility: "Widoczność",
      active_products: "Aktywne produkty",
      inactive_products: "Nieaktywne produkty",
      all_products: "Wszystkie produkty",
      are_you_sure: "Jesteś pewny?",
      do_you_want_to_generate_this_campaign: "Chcesz wygenerować tę kampanię?",
      create_leads_on_order_delivered_status_global:
        "Utwórz potencjalne wiodące status dostarczonego zamówienia (globalny)",
      partner_token: "Token partnera",
      warnings: "Ostrzeżenia",
      fails: "Niepowodzenie",
      success: "Sukcesy",
      comment: "Komentarz",
      selected: "Wybrany",
      items: "rzeczy",
      global_actions: "Działania globalne",
      partners_that_registerd_from_your_ref_link:
        "Partnerzy, którzy rejestrują się z twojego linku Ref:",
      beauty: "Uroda",
      health: "Zdrowie",
      slimming: "Odchudzanie",
      adult: "Dorosły",
      other: "Inny",
      search: "Szukaj",
      latest_news: "Najnowsze wiadomości",
      top_offers: "Najlepsze oferty",
      found: "Znaleziony",
      rows: "wydziwianie",
      balance: "Balans",
      hold: "Wstrzymane",
      your_manager: "Twój menedżer",
      no_manager: "Brak menedżera",
      profile: "Profil",
      log_out: "Wyloguj",
      reportByTimezone: "Raport według strefy czasowej",
      dateType: "Typ daty",
      dateRange: "Zakres dat",
      dateFrom: "Data, od",
      dateTo: "Data do",
      setQuickDate: "Ustaw szybką datę",
      selectedFilters: "Wybrane filtry",
      status: "Status",
      country: "Kraj",
      product: "Produkt",
      lead_type: "Typ leada/rekordu",
      partner: "Partner",
      userEmail: "E-mail użytkownika",
      payoutScheme: "Schemat wypłaty",
      remarketingCampaign: "Kampania remarketingowa",
      classifierName: "Nazwa klasyfikatora",
      accountManager: "Menadżer konta",
      selectTimeZone: "Wybierz strefę czasową",
      timezone: "Strefa czasowa",
      isActive: "Aktywny",
      isExcluded: "Wykluczony",
      isOnline: "Jest online",
      type: "Typ",
      name: "Nazwa",
      domain: "Domena",
      user: "Użytkownik",
      password: "Hasło",
      repeatPassword: "Powtórz hasło",
      baseUrl: "URL podstawy",
      id: "ID",
      email: "E-mail",
      skype: "Skype",
      telegram: "Telegram",
      object: "Obiekt",
      message: "Wiadomość",
      section: "Sekcja",
      phone: "Numer telefonu",
      currentPassword: "Aktualne hasło",
      newPassword: "Nowe hasło",
      repeatNewPassword: "Powtórz nowe hasło",
      apiToken: "Token API",
      click_id: "click_id",
      pub_id: "Pub ID",
      lp_url: "Lp URL",
      second_name: "Drugie imię",
      short_name: "Krótkie imię",
      role: "Rola",
      description: "Opis",
      application_access: "Dostęp do aplikacji",
      pages: "Strony",
      products: "Produkty",
      addressLine1: "Linia adresu 1",
      addressLine2: "Linia adresu 2",
      city: "Miasto",
      state: "Państwo",
      zipCode: "kod pocztowy",
      isInternal: "Wewnętrzny",
      partnersAwaitingActivation: "Partnerzy oczekujący na aktywację",
      allPartners: "Wszyscy partnerzy",
      company: "Firma",
      basedOnRole: "Na podstawie roli",
      campaigns: "Kampanie",
      instances: "Instancje",
      currency: "Waluta",
      code: "Kod",
      address: "Adres",
      operative: "Operacyjny",
      name_eng: "Nazwa (eng)",
      bank_transfer: "przelew bankowy",
      district: "Dzielnica",
      subdistrict: "Subdystrykt",
      island: "Wyspa",
      courier: "Kurier",
      post_code: "Kod pocztowy",
      destination_code: "Kod docelowy",
      lead_time: "Czas realizacji",
      lead_time_text: "Tekst czasu realizacji",
      province: "Województwo",
      delivery_cost: "Koszt dostawy",
      return_cost: "Koszt zwrotu",
      instance_fee: "Opłata za instancję",
      cod_fee: "Opłata COD",
      admin_fee: "opłata administracyjna",
      is_qualified: "Klasyfikowany",
      to_check: "To check",
      is_contacted: "Odebrany",
      affbay_status: "Status Affbay",
      is_visible: "Jest widoczny",
      is_top: "Jest top",
      is_desktop: "stacjonarny",
      is_mobile: "mobilny",
      url: "URL",
      tracker_id: "Identyfikator śledzenia",
      tracker_type: "Typ śledzenia",
      focus_ID: "Focus ID",
      instance: "Instancja",
      signature: "Podpis",
      value: "Wartość",
      courier_status: "Status kuriera",
      status_description: "Opis statusu",
      notes: "Notatki",
      additional_info: "Dodatkowe inf",
      external: "Zewnętrzny",
      transactionType: "Typ transakcji",
      transactionStatus: "Status transakcji",
      customVariables: "Zmienne niestandardowe",
      systemVariables: "Zmienne systemowe",
      newVariable: "Nowa zmienna",
      affbay_lead_id: "Affbay Lead ID",
      amount: "wartość (USD)",
      optional_description: "Opcjonalny opis",
      title: "Tytuł",
      is_home_page: "Widonczne na pulpicie",
      is_home_page_short: "Na pulpicie",
      news_type: "Typ wiadomości",
      visible_on_home_page: "Widonczne na pulpicie",
      date_from: "Start od",
      date_from_short: "Początek",
      date_to: "Koniec od",
      date_to_short: "Koniec",
      time: "Czas",
      hour: "Godzina",
      day: "Dzień",
      week: "Tydzień",
      month: "Miesiąc",
      year: "Rok",
      today: "Dzisiaj",
      lastWeek: "Zeszły tydzień",
      lastMonth: "W zeszłym miesiącu",
      acceptedBalance: "Zaakceptowany bilans",
      acceptedBalanceExplanation: "(Suma filtrowanych rekordów)",
      actualBalance: "Aktualny Bilans",
      requestPayout: "Żądanie wypłaty",
      notAcceptedBalance: "Nie zaakceptowane (oczekujące) transakcje",
      earningsChart: "Wykres zarobków",
      generatedOn: "Wygenerowane na",
      groupBy: "Grupuj według",
      codeName: "Kryptonim",
    },
    informations: {
      active: "Active",
      inactive: "Inactive",
      online: "Online",
      offline: "Offline",
      viewPassword: "View password",
      notAvailable: "n/a",
      qualified: "Qualified",
      notQualified: "Not qualified",
      sessionExpired: "Session expired",
      visible: "Visible",
      invisible: "Invisible",
    },
    modalButtonsTitle: {
      edit: "Edytować",
      createNewInstance: "Dodać",
      createNewClassifier: "Dodać",
      addNewPage: "Dodaj stronę",
      addNewCompany: "Dodaj firmę",
      addNewPartner: "Dodaj nowego partnera",
      addNewUser: "Dodaj użytkownika",
      editUser: "Edytuj użytkowników",
      addNewRole: "Dodaj nową rolę",
      editRole: "Edytuj rolę",
      addNewCountry: "Dodaj nowy kraj",
      addNewCity: "Dodaj nowe miasto",
      addNewCourier: "Dodaj nowego kuriera",
      addNewStatus: "Dodaj nowy status",
      addNewLocation: "Dodaj nową lokalizację",
      addNewPostCode: "Dodaj nowy kod pocztowy",
      addNewMethod: "Dodaj nową metodę",
      addNewProvince: "Dodaj nową prowincję",
      addNewChannel: "Dodaj nowy kanał",
      addNewSubdistrict: "Dodaj nowy subdystrykt",
      addNewCategory: "Dodaj nową kategorię",
      addNewCampaign: "Dodaj nową kampanię",
      editCampaign: "Kampania edytuj",
      addNewLeadsRedirection: "Dodać",
      addNewSystemVariable: "Dodaj nową zmienną",
      addNewTrafficSource: "Dodaj nową zmienną",
      addNewCouriersApiMapping: "Dodaj nowe mapowanie",
      addNewCouriersStatuses: "Dodaj nowy status",
      addNewSystemClassifier: "Dodać",
      deposit: "Depozyt",
      withdraw: "Wycofać",
      addNew: "Dodaj nowe",
    },
    modalTitle: {
      edit: "Edytować",
      editPage: "Edytuj stronę",
      addNewCompany: "Dodaj firmę",
      addNewUser: "Dodaj nowego użytkownika",
      editUser: "Edytuj użytkowników",
      addNewRole: "Dodaj nową rolę",
      editRole: "Edytuj rolę",
      addNewCountry: "Dodaj nowy kraj",
      editCountry: "Edytuj kraj",
      addNewCity: "Dodaj nowe miasto",
      addNewCourier: "Dodaj nowego kuriera",
      editCourier: "Edytuj kurier",
      addNewDistrict: "Dodaj nowy dystrykt",
      addNewStatus: "Dodaj nowy status",
      editStatus: "Edytuj status",
      addNewLocation: "Dodaj nową lokalizację",
      editLocation: "Edytuj lokalizację",
      addNewPostCode: "Dodaj nowy kod pocztowy",
      editPostCode: "Edytuj kod pocztowy",
      addNewMethod: "Dodaj nową metodę",
      editPaymentMethod: "Edytuj metodę płatności",
      addNewCategory: "Dodaj nową kategorię",
      editCategory: "Edytuj kategorię",
      addNewChannel: "Dodaj nowy kanał",
      editChannel: "Edytuj kanał",
      addNewSubdistrict: "Dodaj nowy subdystrykt",
      editSubdistrict: "Edytuj dzielnicę",
      addNewProvince: "Dodaj nową prowincję",
      deleteProvince: "Usuń prowincję",
      editCampaign: "Kampania edytuj",
      editLeadsRedirection: "Edytuj prowadzi przekierowanie",
      addNewSystemVariable: "Dodaj nową zmienną systemową",
      addNewTrafficSource: "Dodaj nowe źródło ruchu",
      addNewCouriersApiMapping: "Dodaj nowe mapowanie API Couriers",
      addNewCouriersStatuses: "Dodaj status nowych kurierów",
      editCouriersStatus: "Edytuj status kurierów",
      editCouriersApiMapping: "Edytuj mapowanie API Couriers",
      addNewSystemClassifier: "Dodaj nowy klasyfikator systemu",
      editSystemClassifier: "Edytuj klasyfikator systemu",
      editTrafficSource: "Edytuj źródło ruchu",
      deposit: "Depozyt",
      withdraw: "Wycofać",
      transaction: "Transakcja",
      addNew: "Dodaj nowe",
      edit_news: "Edytuj wiadomości",
      sendFeedback: "Wyślij opinię",
    },
    modalTooltips: {
      see_company_members: "Patrz członków firmy",
      addNewUser: "Dodaj nowego użytkownika",
      addNewRole: "Dodaj nową rolę",
      addNewCountry: "Dodaj nowy kraj",
      editCountry: "Edytuj kraj",
      addNewCity: "Dodaj nowe miasto",
      editCity: "Edytuj miasto",
      addNewCourier: "Dodaj nowego kuriera",
      editCourier: "Edytuj kurier",
      addNewStatus: "Dodaj nowy status",
      editStatus: "Edytuj status",
      addNewLocation: "Dodaj nową lokalizację",
      editLocation: "Edytuj lokalizację",
      addNewPostCode: "Dodaj nowy kod pocztowy",
      editPostCode: "Edytuj kod pocztowy",
      addNewMethod: "Dodaj nową metodę",
      editCategory: "Edytuj kategorię",
      addNewChannel: "Dodaj nowy kanał",
      editChannel: "Edytuj kanał",
      addNewSubdistrict: "Dodaj nowy subdystrykt",
      editSubdistrict: "Edytuj dzielnicę",
      addNewProvince: "Dodaj nową prowincję",
      deleteProvince: "Usuń prowincję",
      addNewCategory: "Dodaj nową kategorię",
      addNewSystemVariable: "Dodaj nową zmienną systemową",
      addNewTrafficSource: "Dodaj nowe źródło ruchu",
      editTrafficSource: "Edytuj źródło ruchu",
      addNewCouriersApiMapping: "Dodaj nowe mapowanie API kuriera",
      addNewCouriersStatuses: "Dodaj status nowych kurierów",
      editCouriersApiMapping: "Edytuj mapowanie API kuriera",
      editCouriersStatus: "Edytuj status kuriera",
      addNewSystemClassifier: "Dodaj nowy klasyfikator systemowy",
      editSystemClassifier: "Edytuj klasyfikator systemowy",
      filters: "Filtry",
      deposit: "Depozyt",
      withdraw: "Wycofać",
    },
    tables: {
      campainFocusTable: "CampainFocusTable",
    },
    title: {
      edit_account_manager: "Edytuj menedżer konta",
      createNewInstance: "Utwórz nową instancję",
      createNewClassifier: "Utwórz nowy klasyfikator",
      editClassifier: "Edytuj klasyfikator",
      editPartner: "Edytuj partner",
      editEmployee: "Edytuj pracownika",
      editCompany: "Edytuj firmę",
      addNewPartner: "Dodaj nowego partnera",
      addNewEmployee: "Dodaj nowego pracownika",
      addNewCompany: "Dodaj nową firmę",
      selectInstance: "Wybierz instancję",
      selectInstanceAndCampaign: "Wybierz instancję i kampanię",
      campaignsAllInstances: "Kampanie we wszystkich przypadkach",
      all: "Wszystko",
      allArrowAll: "Wszystkie -> wszystko",
      allArrow: "All ->",
      arrowAll: "-> Wszystkie",
      createNewProduct: "Utwórz nowy produkt",
      campaignsActivityStatus: "Zmień status aktywności klasyfikatora",

      userActivityStatus: "Zmień status aktywności użytkowników",
      filters: "Filtry",
      activityStatus: "Zmień status aktywności",
      addNewPage: "Dodaj nową stronę",
      addNewCampaign: "Dodaj nową kampanię",
      addNewLeadsRedirection: "Dodaj nowe leady przekierowania",
      notFoundLabel: "Przepraszamy, odwiedzona strona nie istnieje.",
      addNew: "Dodaj nowe",
      new: "Nowy",
      change: "Zmiana",
      inProgressTitle: "Strona w toku",
      inProgressDesc: "Przepraszamy, odwiedzona strona jest nadal w toku.",
    },
    columnTitle: {
      to_check: 'To check',
      product_sku: "Produkt SKU",
      product_sku_short: "SKU",
      comment: "Komentarz",
      classifier_end: "Klasyfikator kończący",
      phone_origin: "Telefon (oryginalny)",
      id: "ID",
      type: "Typ",
      country: "Kraj",
      country_short: "Współ.",
      name: "Nazwa",
      second_name: "Drugie imię",
      short_name: "Krótkie imię",
      campaign_name: "Nazwa kampanii",
      domain: "Domena",
      user: "Użytkownik",
      password: "Hasło",
      password_short: "Przechodzić",
      url: "Podstawowy adres URL CAMPAIN",
      url_short: "URL",
      active: "Rzeczywisty stan aktywności kampanii",
      active_short: "Aktywny",
      // classifier_is_qualified: "„Rzeczywisty status kwalifikowanego klasyfikatora”",
      // classifier_is_qualified_short: "'Wykwalifikowany'",
      online: "Rzeczywisty status kampanii online",
      online_short: "online",
      created: "Data utworzenia",
      created_short: "Utworzony",
      updated: "Ostatnio zaktualizowane",
      updated_short: "Zaktualizowane",
      options: "Opcje",
      instance: "Instancja",
      focus_instance: "Instancja Focus",
      classifiers_external: "Klasyfikatory zewnętrzne",
      affbay_status: "Status Affbay",
      status: "Status",
      qualified: "Wykwalifikowany",
      approvedAt: "Zatwierdzony",
      approvedAt_short: "Data zatwierdzona na",
      classifier: "Klasyfikator",
      product: "Produkt",
      partner: "Partner",
      payout: "Wypłata",
      payout_scheme: "Schemat wypłaty",
      payout_scheme_short: "Schemat",
      phone: "Telefon",
      click_id: "kliknij_id",
      pub_id: "Identyfikator pubu",
      lp_url: "Lp URL",
      role: "Rola",
      description: "Opis",
      application_access: "Dostęp do aplikacji",
      avalable_in_affbay: "Dostępne w Affbay",
      avalable_in_affbay_short: "W Affbay",
      avalable_for_sale: "Dostępny na sprzedaż",
      avalable_for_sale_short: "Na sprzedaż",
      base_price: "Ceny podstawowe",
      base_price_short: "Cena",
      visible_for_partners: "Widoczne dla partnerów",
      visible_for_partners_short: "Dla partnerów",
      categories: "Kategorie",
      landing: "Lądowanie",
      preview: "Zapowiedź",
      is_visible: "Widoczny",
      is_top: "Szczyt",
      is_mobile: "mobilny",
      is_desktop: "Pulpit",
      deleted_at: "Usunięte",
      accountCounter: "Licznik konta",
      isInternal: "Wewnętrzny",
      partnerToken: "Token partnera",
      email: "E-mail",
      manager: "Menedżer",
      company: "Firma",
      skype: "Skype",
      telegram: "Telegram",
      is_active_short: "Aktywny",
      key: "Klucz",
      focusId: "Focus Id",
      is_operative: "Operacyjny",
      address: "Adres",
      code: "Kod",
      currency: "Waluta",
      is_active: "Aktywny",
      name_eng: "Nazwa (eng)",
      bank_transfer: "przelew bankowy",
      city: "Miasto",
      province: "Województwo",
      district: "Dzielnica",
      subdistrict: "Subdystrykt",
      postCode: "Kod pocztowy",
      courier: "Kurier",
      lead_time: "Czas realizacji",
      lead_time_text: "Tekst czasu realizacji",
      district_code: "Kod okręgowy",
      island: "Wyspa",
      deliver_cost: "Dostarcz koszty",
      resturn_cost: "Koszt zwrotu",
      insurance_fee: "Opłata ubezpieczeniowa",
      code_fee: "Opłata za kod",
      admin_fee: "opłata administracyjna",
      dalivery_cost: "Koszt dostawy",
      permission_id: "ID zezwolenia",
      is_qualified: "Wykwalifikowany",
      is_contacted: "Skontaktowano się",
      is_qualified_short: "Wykwalifikowany",
      value: "Wartość",
      courier_name: "Nazwa kuriera",
      courier_name_short: "Kurier",
      courier_status: "Status kuriera",
      status_description: "Opis statusu",
      notes: "Notatki",
      external: "Zewnętrzny",
      additional_inf: "Dodatkowe informacje",
      additional_inf_short: "Informacje",
      view: "Pogląd",
      is_manual_short: "podręcznik",
      is_manual: "Jest ręcznym",
      is_successful_short: "Udany",
      is_successful: "Zakończony powodzeniem",
      response_time_short: "Czas",
      response_time: "Czas odpowiedzi",
      response_status_short: "Status",
      response_status: "Status odpowiedzi",
      response_text_short: "Tekst",
      response_text: "Tekst odpowiedzi",
      title: "Tytuł",
      is_home_page: "Widoczne na desce rozdzielczej",
      is_home_page_short: "Na desce rozdzielczej",
      news_type: "Typ wiadomości",
      visible_on_home_page: "Widoczne na desce rozdzielczej",
      date_from: "Formularz rozpoczęcia",
      date_from_short: "Początek",
      date_to: "Formularz końcowy",
      date_to_short: "Koniec",
      scheme: "Schemat",
      leadId: "Prowadzenie",
      accepted: "Przyjęty",
      date: "Data",
      params: "Params",
      exclude_from_double: "Wykluczyć z podwójnego",
      exclude_from_double_short: "Podwójne wykluczenie",
    },
    tooltips: {
      see_company_members: "See company members RU",
      hide_postbacks: "Hide postbacks",
      show_postbacks: "Show postbacks",
      copyKey: "Copy key",
      editPage: "Edit page",
      deletePage: "Delete page",
      cantDeleteAdminSuperAdmin: `Can't delete Admin/Super admin`,
      userActivityStatus: "Change users activity status",
      deleteUser: "Delete user",
      changeRoleActiveStatus: "Change roles sctive status",
      editRole: "Edit role",
      deleteRole: "Delete role",
      changeActiveStatus: "Change active status",
      viewCampaign: "View campaign",
      viewCompany: "View company",
      changeQualifiedStatus: "Change qualified status",
      deleteStatus: "Delete status",
      deletePostCode: "Delete post code",
      deleteCstegory: "Delete category",
      change_activity_status: "Change activity status",
      delete_leads_direction: "Delete direction",
      edit_leads_direction: "Edit leads redirection",
      delete: "Delete",
      edit: "Edit",
      accept: "Accept",
      reject: "Reject",
    },
    statement: {
      unrecognized_error: "Unrecognized error",
      refresh_page: "Refresh page",
      your_account_has_been_created: "Your account has been activated",
      your_account_has_been_created_message:
        "Now you need to wait for our specialist to accept your account. ",
      sorry_we_could_not_get_your_data: `Sorry, we couldn't get your data`,
      databaseConnectionError:
        "Something went wrong while connecting to the database!",
      databaseConnectionSuccess: "Udane pobranie z bazy danych!",
      instanceCreated: "Udane dodanie instancji!",
      classifierCreated: "Classifier created!",
      classifierUpdated: "Classifier updated!",
      thePropsAreTheSame:
        "Wartości w polach nie uległy zmianie - do ustalenia co z tym robimy",
      campaignUpdated: "Udana aktualizacja kampanii!",
      qulifiedUpdate: "Udana zmiana kwalifikacji",
      app_user_updated: "App user updated!",
      app_user_created: "App user created!",
      status_updated: "Status updated!",
      there_was_a_problem_removing_app_user:
        "There was a problem removing app user",
      app_user_deleted: "App user deleted",
      there_was_a_problem_removing_role: "There was a problem removing role",
      there_was_a_problem_removing_permission:
        "There was a problem removing permission",
      there_was_a_problem_creating_role: "There was a problem creating role",
      user_role_updated: "User role updated!",
      companie_created: "Companie created!",
      companie_updated: "Companie updated!",
      there_was_a_problem_removing_city: "There was a problem removing city",
      city_deleted: "City deleted!",
      city_created: "City created!",
      city_updated: "City updated!",
      there_was_a_problem_removing_country:
        "There was a problem removing country",
      country_created: "Country deleted!",
      country_updated: "Companie updated!",
      there_was_a_problem_removing_courier:
        "There was a problem removing courier",
      courier_deleted: "Courier deleted!",
      courier_updated: "Courier updated!",
      courier_created: "Courier created!",
      there_was_a_problem_removing_district:
        "There was a problem removing district",
      district_deleted: "District deleted!",
      district_updated: "District updated!",
      district_created: "Dictrict created!",
      there_was_a_problem_removing_location:
        "There was a problem removing location",
      location_deleted: "Location deleted!",
      location_updated: "Location updated!",
      location_created: "Location created!",
      there_was_a_problem_removing_payment_method:
        "There was a problem removing payment method",
      payment_method_deleted: "Payment method deleted!",
      payment_method_updated: "Payment method updated!",
      payment_method_created: "Payment method created!",
      there_was_a_problem_removing_post_code:
        "There was a problem removing post code",
      post_code_deleted: "Post code deleted!",
      post_code_updated: "Post code updated!",
      post_code_created: "Post code created!",
      there_was_a_problem_removing_product_category:
        "There was a problem removing product category",
      product_category_deleted: "Product category deleted!",
      product_category_updated: "Product category updated!",
      product_category_created: "Product category created!",
      there_was_a_problem_removing_province:
        "There was a problem removing province",
      province_deleted: "Province deleted!",
      province_updated: "Province updated!",
      province_created: "Province created!",
      there_was_a_problem_removing_sales_channel:
        "There was a problem removing sales channel",
      sales_channel_deleted: "Sales channel deleted!",
      sales_channel_updated: "Sales channel updated!",
      sales_channel_created: "Sales channel created!",
      there_was_a_problem_removing_status:
        "There was a problem removing status",
      status_deleted: "Status deleted!",
      status_created: "Status created!",
      there_was_a_problem_removing_subdistrict:
        "There was a problem removing subdistrict",
      subdistrict_deleted: "Subdistrict deleted!",
      subdistrict_updated: "Subdistrict updated!",
      subdistrict_created: "Subdistrict created!",
      employee_updated: "Employee updated!",
      employee_created: "Employee created!",
      filters_confirmed: "Filtry potwierdzone",
      filters_have_been_reset: "filters have been reset",
      there_was_a_problem_removing_page: "There was a problem removing page",
      page_deleted: "Page deleted!",
      page_updated: "Page updated!",
      page_created: "Page created!",
      partner_updated: "partner updated!",
      partner_created: "partner created!",
      change_permission_status: "Change permissions status",
      there_was_a_problem_removing_campaign:
        "There was a problem removing campaign",
      there_was_a_problem_creating_campaign:
        "There was a problem creating campaign",
      campaign_deleted: "Campaign deleted!",
      campaign_updated: "Campaign updated!",
      campaign_created: "Campaign created!",
      role_deleted: "Role deleted!",
      choose_out_of_scope: "Choose out of the scope!",
      date_changed: "Date changed",
      session_expired: "Session expired",
      session_expired_description:
        "Your session has expired. Please login again to continue working",
      incorrect_login_information:
        "Your login information was incorrect. Please check and try again",
      there_was_a_problem_removing_system_variable:
        "There was a problem removing system variable",
      system_variable_deleted: "System variable deleted!",
      system_variable_updated: "System variable updated!",
      system_variable_created: "System variable created!",

      traffic_source_deleted: "Traffic source deleted!",
      traffic_source_updated: "Traffic source updated!",
      traffic_source_created: "Traffic source created!",

      there_was_a_problem_removing_couriers_api_mapping:
        "There was a problem removing couriers api mapping",
      couriers_api_mapping_deleted: "Couriers api mapping deleted!",
      couriers_api_mapping_updated: "Couriers api mapping updated!",
      couriers_api_mapping_created: "Couriers api mapping created!",
      system_classifier_created: "System classifierl created!",
      system_classifier_updated: "System classifierl updated!",
      updated: "Updated!",
      created: "Created!",
      deleted: "Deleted!",
      success: "Success!",
      accepted: "Accepted!",
      rejected: "Rejected",
      noCompanyIdAssigned:
        "Your account has no company assigned. Please contact us through support panel.",
      noDataToDisplay: "Sorry, there is no data to display",
    },
    tabs: {
      createNewProduct: "create new product",
      pricing: "Pricing",
      conversion: "Conversion",
      affbayVisibility: "Affbay visibility",
      landings: "Landings / Prelandings",
    },
    navigationItems: {
      dashboard: "Panel",
      statistics: "Statystyka",
      financeStatistics: "Statystyka finansowa",
      leads: "Leady / Rekordy",
      employees: "Pracownicy",
      products: "Produkty",
      pages: "Strony",
      landings: "Strona docelowa/Strona produktowa",
      companies: "Firmy",
      partners: "Partnerzy",
      campaigns_partner: "Kampanie",
      campaigns_admin: "Kampanie administratora",
      finance: "Finanse",
      financeAdmin: "Administrator finansów",
      tools: "Narzędzia",
      news: "Aktualności",
      news_admin: "NEWS Admin",
      support: "Wsparcie",
      faq: "FAQ",
      contact: "Kontakt",
      login: "Zaloguj sie",
      register: "Zarejestruj się",
      offers: "Oferty",
      create_new_order: "Utwórz nowe zamówienie",
      orders: "Zamówienia",
      couriersStatusMapping: "Mapowanie statusu",
      account_managers: "Menadżerowie konta",
    },
    subNavigationItem: {
      k8_services: "Usługi K8",
      sales: "Sprzedaż",
      sales_p2: "P2",
      sales_resale: "P3",
      sales_winback: "Odegrać się",
      administration: "Administracja",
      appRemarketing: "Remarketing - P2",
      appUsers: "Użytkownicy",
      appUsersRole: "Role użytkowników",
      webSocket: "WebSocket",
      appUsersPermissions: "Uprawnienia użytkowników",

      callCenter: "Call cemter",
      cc_instances: "Instancje",
      cc_campaigns: "Kampanie",
      cc_classifiers: "Klasyfikatory",
      li_classifiers_cc: "Klasyfikatory mapowania",
      cc_webhooks: "Webhooks",
      cc_leads_direction: "Przekierowanie leadów/rekordów",

      setting: "Ustawienia",
      api: "API",
      payment: "Zapłata",
      postbacks: "Postbacks",
      referralHistory: "Historia poleceń",
      security: "Bezpieczeństwo",
      settings: "Ustawienia",

      dictionaries: "Słowniki",
      countries: "Kraje",
      cities: "Miasta",
      couriers: "Kurierzy",
      districts: "Dzielnice",
      subdistricts: "Poddystrykty",
      statuses: "Statusy",
      locations: "Lokalizacje",
      postCodes: "Kod pocztowy",
      paymentMethods: "Metody Płatności",
      productCategories: "Kategoria produktu",
      salesChannels: "Kanały sprzedaży",
      provinces: "Prowincje",
      system_variables: "Zmienne systemowe",
      traffic_sources: "Źródła ruchu",
      couriers_api_mapping: "Mapowanie kurierów",
      couriers_statuses: "Statusy kurierów",
      system_classifiers: "Klasyfikatory systemowe",
    },
    others: {
      of: "z",
      rows: "wierszy",
    },
    contentTitle: {
      support: "Support",
    },
    contentText: {
      alreadyHaveAnAccount: "Already have an account?",
      iDontHaveAnAccount: "I don't have an account.",
      rememberThePassword: "Remember the password?",
      support:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us.",
    },
    links: {
      all_news: "All news",
      login: "Login",
      registration: "Registration",
      forgotYourPassword: "Forgot your password?",
    },
    placeholders: {
      enterName: "Wprowadź imię",
      enterEmail: "Wprowadź e-mail",
      enterPassword: "Wprowadź hasło",
      repeatPassword: "Powtórz hasło",
      selectCountry: "Wybierz kraj",
      notRequired: "Nie wymagane",
      select: "Wybierz.",
      globalSearch: "Globalne wyszukiwanie",
      search: "Szukaj",
      findProduct: "Znajdź produkt",
      choose_affbay_status: "Wybierz status Affbay",
      choose_country: "Wybierz kraj",
      choose_company: "Wybierz firmę",
      choose_product: "Wybierz produkt",
      choose_partner: "Wybierz partnera",
      choose_scheme: "Wybierz schemat",
      choose_classifier: "Wybierz klasyfikator",
      choose_remarketing_campaign: "Wybierz kampanię",
      enter_min_4_letters: "Wpisz min 4 litery",
    },
    pageTitle: {
      k8_services: "Usługi K8",
      dashboard: "Panel",
      account_managers: "Menadżerowie konta",
      loginContent: "Zaloguj sie",
      registerContent: "Rejestracja",
      resetPasswordContent: "Przywróć hasło",
      employees: "Wszyscy pracownicy PL + wszyscy pracownicy call center",
      products: "Produkty",
      pages: "Strony",
      addNewCompany: "Dodaj firmę",
      companies: "Firmy",
      partners: "Wzmacniacz",
      administrationUsers: "Administracja - Użytkownicy",
      administrationUsersRole: "Administracja - rola użytkowników",
      instances: "Instancje",
      campaigns: "Kampanie",
      classifiers: "Klasyfikatory",
      mappingClassifiers: "Klasyfikatory mapowania",
      webhooks: "Webhooks",
      countriesDictionary: "Słownik krajów",
      citiesDictionary: "Słownik miast",
      couriersDictionary: "Słownik Couriers",
      districtsDictionary: "Słownik okręgów",
      statusesDictionary: "Słownik statusu",
      locationsDictionary: "Lokalizacje słownik",
      postCodeDictionary: "Słownik kodów post",
      paymentMethodDictionary: "Słownik metod płatności",
      productCategoriesDictionary: "Słownik kategorii produktów",
      salesChannels: "Słownik kanałów sprzedaży",
      subdistrictsDictionary: "SŁOWNIK SUBDICRICTS",
      provincesDictionary: "Słownik prowincji",
      campaigns_admin: "Kampanie - admin",
      orders: "Zamówienia",
      leads_directions: "Prowadzą przekierowania",
      system_variables: "Zmienne systemowe",
      traffic_sources: "Źródła ruchu",
      couriers_api_mapping: "Mapowanie API Couriers",
      system_classifiers: "Klasyfikatory systemowe",
      finance: "Finanse",
      news_admin: "NEWS Admin",
      finance_admin_view: "Widok administratora finansów",
      resaleP3: "P3 Odsprzedaż",
      resaleP2: "Odsprzedaż P2",
      instancesForCCSimplified: "Połączenie API Call Center",
    },
    productsFormMain: {
      editProduct: "Edit product",
      createNewProduct: "Create new product",
      englishName: "English name",
      currency: "Currency",
      callCenter: "Call Center",
      sku: "SKU",
      originalName: "Original name",
      shortName: "Short name",
      printName: "Print name",
      categories: "Categories",
      availableInAffbay: "Available in AFFBAY",
      testProduct: "Test product",
      availableForSale: "Available for sale",
      active: "Active",
      country: "Country",
      sales: "Sales",
      mainCamapignId: "Main campaign [id]",
      resellCampaignId: "Resell campaign [id]",
      productionCost: "Production cost (USD)",
      productionCostLocalCurrency: "Production cost (local currency)",
      affbayVisibility: "Affaby Visibility",
      visibleForPartners: "Visible for partners",
      visibleOnHomePage: "Visible on home page (top offers)",
      isRestricted: "Is restricted",
      defaultGlobalPayout: "Default global payout (USD)",
      defaultPayoutScheme: "Default payout scheme",
      cpa: "CPA",
      cpl: "CPL",
      landingPriceForConsumer: "Landing price for consumer",
      approveVisibleOnPage: "Approve visible on page (%)",
      payoutVisibleOnPage: "Payout visible (string) on page (USD)",
      descriptionEN: "Description EN",
      descriptionRU: "Description RU",
      traffic_sources_en: "Traffic sources (description) EN",
      traffic_sources_ru: "Traffic sources (description) RU",
      terms_en: "Terms EN",
      terms_ru: "Terms RU",
      conditions_en: "Conditions EN",
      conditions_ru: "Conditions RU",
      productsWillNotBeAvailableInAffbay:
        "Products will not be available in AFFBAY",
      updateCampaigns: "Refresh campaings list",
      save: "Save",
      create: "Create",
      reset: "Reset",
    },
    checkBoxes: {
      iWantToReceiveImportantNewsByEmail:
        "I want to receive important news by email (recommended)",
      iWantToReceivePromotionslNewsByEmail:
        "I want to receive promotional news by email",
      iWantToReceiveInformationAboutChangesInOffersByEmail:
        "I want to receive information about changes in offers by email",
      iWantToReceiveOffersOfOffersByEmail:
        "I want to receive offers of offers by email",
      add_to_affbay_classifiers_library: "Add to Affbay Classifiers library",
    },
    landingPage: {
      about: "About",
      topOffers: "Top offers",
      contact: "Contact",
      logIn: "Log In",
      certifiedProducts: "CERTIFIED PRODUCTS FROM DIRECT ADVERTISER",
      afiliateNetwork: "Affiliate Network in Thailand",
      saudiArabAndIndonesia: "Saudi Arabia and Indonesia",
      becomeAPartner: "Become a partner",
      activePartners: "Active partners",
      offersInAsia: "Offers in Asia",
      yearsOnTheMarket: "Years on the market",
      countries: "Countries",
      aboutText:
        "Affbay is a direct advertiser in the markets of Asia and the Arab countries. We do not resell the offers, all products are our own. Here you will find dozens of offers on the topic of beauty, health and weight loss with payments from $ 18 higher for a confirmed application. We work on models CPA, CPL, CPI.",
      regularPayouts: "Regular Payouts",
      regularPayoutsText: "Payouts without delay on most payment systems.",
      highPayouts: "High Payouts",
      highPayoutsText: "From $ 18 and above for a confirmed application.",
      ownCallCenter: "Own call center",
      ownCallCenterText:
        "Experienced CC specialists performing work efficiently.",
      personalManager: "Personal manager",
      personalManagerText:
        "Responsive support will help you solve any problems.",
      weDontHaveTopOffers: "We don't have any top offers right now.",
      howCanWeHelpYou: "How can we help you?",
      writeUs: "Write us about your concerns.",
      becomeOurPartner: "Become our partner and start earning right now",
      affbayAsia: " Affbay.asia",
      ownedBy: "Owned By: Izabella Frolushkin FZE",
      privacyPolicy: "Privacy Policy",
      termsAndConditions: "Terms and Conditions",
      send: "Send",
      name: "Name",
      email: "E-mail",
      object: "Object",
      message: "Message",
      thankYou: "Thank you",
      youWillBeHearing:
        "Your message was sent. We will handle your issue with 48 hours.",
      startEarning: "Start earning",
      sitePrice: "Site price",
      approve: "Approve",
      payouts: "Payouts",
      country: "Country",
    },
    loginPage: {
      logIn: "Logowanie",
      registration: "Rejestracja",
      email: "E-mail",
      password: "Hasło",
      forgotYourPassword: "Zapomniałeś hasła?",
      logIntoThePlatform: "Zaloguj się do platformy",
      iDontHaveAnAccount: "Nie mam konta.",
      send_request_again: "Wyślij żądanie ponownie",
    },
    registrationPage: {
      registration: "Registration",
      name: "Name",
      email: "E-mail",
      password: "Password",
      repeatPassword: "Repeat password",
      country: "Country",
      skype: "Skype",
      telegram: "Telegram",
      notRequired: "Not required",
      iAgreeToAffbayAsia: "I agree to Affbay Asia",
      privacyPolicy: "Privacy Policy",
      and: "and",
      termsAndConditions: "Terms and Conditions",
      nextStep: "Next Step",
      alreadyHaveAnAcount: "Already have an account?",
      logIn: "Log in",
      thxForReg: "Thank you for registration",
      toStartWork: "To start work please provide us few information",
      surveyIsNotAvailable: "Survey is not available",
      thisAccountIsA: "This account is a...",
      whichgeos: "Which geos you have worked with before?",
      whatgeo: "What geos do you want to work with?",
      whatTypeOfTraffic: "What type of traffic are you using?",
      whatBugdets: "What budgets did you work with?",
      whatDoYouPrefer: "What do you prefer - S2S or API?",
      submitMyApplication: "Submit my application",
      enterYourName: "Enter your name",
      enterEmail: "Enter e-mail",
      enterPassword: "Enter password",
    },
    submitSuccess: {
      congrats: "Congratulations, your account has been created!",
      nowYouCan: "Now you can login to your account",
      logIn: "Log In",
    },
    resetPassword: {
      password_changed: "Your password has been changed. You can login now.",
      typeTheAdress:
        "Type the address linked to your account and we'll send you password reset instructions. Also, check your spam folder.",
      email: "E-mail",
      set_new_password: "Set new password",
      remember: "Remember your password?",
      logIn: "Log In",
      checkEmail: "Check your e-mail",
      withinAFew:
        "Within a few minutes you will receive an email. In this message we will send you a link to set new password.",
      new_password: "New password",
    },
    supportView: {
      support: "Support",
      ifYouNeedHelp:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us.",
      subject: "Subject",
      message: "Message",
      send: "Send",
    },
    newsView: {
      news: "News",
      allNews: "All news",
    },
    offersView: {
      offers: "Offers",
      offersFound: "offers found",
      show: "Show:",
      sortBy: "Sort by:",
      category: "Category",
      conversionType: "Conversion type",
      sort: "Sort",
      accessRequest: "Access Request",
      wirteUs:
        "Write us about traffic sources, with whom you work and your experience.",
      send: "Send",
      thankYou: "Thank you",
      youWillBeHearing: "You will be hearing from us soon!",
      sitePrice: "Site price",
      payouts: "Payouts",
      country: "Country",
      originalCountry: "Original country",
      readMore: "Read more",
      exampleCodePhp: "Download example",
    },
    offersDetails: {
      sku: "SKU",
      // tabs
      description: "Description",
      targetURL: "Target URL",
      postbacks: "Postbacks",
      trafficSources: "Traffic sources",
      news: "News",
      terms_and_conditions: "Terms & conditions",
      landings_tab: "Landings",
      // product card
      payout: "Payout",
      sitePrice: "Site price",
      category: "Category",
      country: "Country",
      devices: "Devices",
      // target url
      step_1:
        "Step 1. Choose landing page and prelanding. You can choose multiple items which be opened randomly in your campaign",
      step_2:
        "Step 2. Enter new name for your campaign and click Generere URL button to save it.",
      landings: "Landings",
      prelandings: "Prelandings",
      campaign_name: "Campaign name",
      generateURL: "Generate URL",
      allProductCampaigns: "All product campaigns",
      name: "Name",
      prelanding: "Prelanding",
      landing: "Landing",
      URL: "URL",
      youHaveTo: "You have to choose LP and type Campaign name",
      noLandings: "No pages to display",
      // postbacks
      urlForSales: "Postback URL for Sales (means APPROVE for CPL)",
      urlForHolds: "Postback URL for Holds (means APPROVE for CPL)",
      urlForRejects: "Postback URL for Rejects (means APPROVE for CPL)",
      urlForTrash: "Postback URL for Trash (means REJECTED for CPL)",
      urlForDuplicated: "Postback URL for Duplicated (means REJECTED for CPL)",
      urlForExcluded: "Postback URL for Excluded (means REJECTED for CPL)",
      urlForValidated: "Postback URL for Validated",
      urlForCheck: "Postback URL for Check",
      googleTrackingID: "Google tracking ID",
      facebookPixelID: "Facebook pixel ID",
      customPixelID: "Custom pixel ID",
      useGlobalPostbacks: "Use global postbacks",
      createPostback: "Create postback",
      updatePostback: "Update postback",
      fillInTheData: "Fill in the data below to create a new postback",
      protocol: "Protocol",
      domain: "Domain",
      exampleCOM: "example.com",
      extraPathParameter: "Extra path parameter",
      folderSlash: "/folder/subfolder",
      parameter: "Parameter",
      addNew: "Add new",
      postbackURLPreview: "Postback URL Preview",
      save: "Save",
      basic: "Basic",
      advanced: "Advanced",
      postbackSavedSuccessfully: "Postback saved successfully",
      postbackSavedWithWarning: "Postback saved with warning",
      postbackNotSaved: "Unable to saved postback",
      clearPostback: "Clear Postback",
    },
    profileView: {
      personlaData: "Dane osobiste",
      changePassword: "Zmień hasło",
      notifications: "Notokacje",
      apitoken: "Token API",

      email: "E-mail",
      name: "Nazwa",
      phone: "Numer telefonu",
      telegram: "Telegram",
      skype: "Skype",
      timeZone: "Strefa czasowa",
      apply: "Stosować",
      cancel: "Anulować",
      id_referral: "Link rejestracyjny",

      currentPass: "Aktualne hasło",
      newPass: "Nowe hasło",
      repeatNewPass: "Powtórz nowe hasło",
      change: "Zmiana",

      importantNews: "Chcę otrzymać ważne wiadomości e -mail (zalecane)",
      promotionalNews: "Chcę otrzymywać wiadomości promocyjne przez emial",
      information:
        "Chcę otrzymywać informacje o zmianach w ofertach pocztą elektroniczną",
      offers: "Chcę otrzymać oferty ofert e -maila",
      customVariablesList: "Lista zmiennych niestandardowych",
      postbacksList: "Lista postbacków",
    },
    toolsView: {
      open: "Open",
      tools: "Tools",
      // faq
      faq: "FAQ",
      answersToFAQ:
        "Answers to frequently asked questions regarding payments for their implementation and Affbay in general",
      readMore: "Read more",
      // API Documentation
      apiDocs: "API Documentation",
      leadSendExample: "Send lead example form",
      readOurDocs:
        "Read our documentation to get answers to many questions regarding the API.",
      readDocs: "Read documentation",
      // postback documentation
      postbackDocs: "Postback Documentation",
      postbacksNotify:
        "Read our documentation to get answers to many questions regarding the API.",
      // integration archi model
      integrationArchiModel: "Integration Architecture Model",
      hardToUnderstand:
        "Hard to understand how Affbay works? Read more about a few examples of our architecture.",
      // number validation
      numberValidation: "Number validation",
      checkingTheNumber:
        "Checking the number is the key to improving the quality of customer acquisition and revenue.",
      // longpage widget
      longpageFromWidget: "Longpage form widget",
      longpageFromWidgetDescription:
        "Include our form into your webpage. Simply paste our code, add your token and sku to start generating new leads.",
    },
    select: {
      select_timezone: "Wybierz strefę czasową",
      select_status: "Wybierz status",
      select_available: "Wybierz dostępne",
      select: "Wybierz",
      select_country: "Wybierz kraj",
    },
    spin: {
      loading: "Loading...",
    },
    faq: {
      header_1: "What is Affbay Asia?",
      header_2: "What kinds of integration do we support?",
      header_3: "Do you need your own tracker to integrate by S2S?",
      header_4: "Which parameters are supported by Affbay (S2S integration)?",
      header_5: "How to add parameters you want to receive?",
      header_6: "Which parameter is Affbay’s?",
      header_7: "How to configure general postbacks for S2S?",
      header_8: "What is postback for sales, holds, rejected?",
      header_9: "How to configure getting postback for concrete offer?",
      header_10: "How to add payout to postback url?",
      header_11: "How should a postback url look like?",
      content_1:
        "We’re direct advertisers in Indonesia and Thailand, we have our own high conversion COD offers. More geos in Asia coming soon!",
      content_2:
        "We can integrate with you by S2S or by API. Documentation for API is provided on our website in section Offers (at the top of webpage). Choose offers you like and click Details.",
      content_3:
        "Affbay is a user friendly platform, which provides you with your personal account with dashboard and requires no tracker, although we can provide you S2S postback integration with your trackers.",
      content_4:
        'You can add any parameter that is supported by your tracker. See "How to add parameters you want to receive?".',
      content_5:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Just add any parameter without brackets {} () and put it also in your postback url.",
      content_6: [
        "Our built in parameters are:",
        "click_id - global identifier (UUID) of a specific lead",
        "campaign_id - global identifier (UUID) of a specific campaign",
        "aff_id - global identifier (UUID) of a specific user (you).",
        "If you specify a custom variable named as one of the above, postback will use it, substituting your tracker data instead of our default data. We discourage this to avoid confusion.",
      ],
      content_7:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Here you can add you general postback url. If you want to receive postbacks for sales – just put your postback url in this field, the same for postback for holds and rejected. Also you can add Facebook Pixel and Google Tracking ID for all the offers.",
      content_8:
        "If you want to receive postback for approved leads – choose postback for sales. Postback for holds means getting postback for every lead that came into our system. Postback for rejected means getting postback for leads that were rejected – invalid phone number or leads not being reached by Call Center in a month.",
      content_9:
        "Enter section Offers (at the top of webpage) -> choose offers and click Details -> there you can add postback urls and also Facebook Pixel and Google Tracking ID for specific offer.",
      content_10: "&amount=13&payout_type=pps¤cy=USD",
      content_11:
        "https://callbacks.bitterstrawberry.org/?token=0c4c1249825285a2997bacf09c09ec28&hash={hash}&transaction_id={click_id}&amount=13&payout_type=pps¤cy=USD",
    },
    news_type: {
      all_news: "All news",
      new: "New",
      important: "Important",
      info: "Info",
    },
    setting_page: {
      click_id: "Click ID",
      check_click_id: "Check click ID doubles",
      click_id_days: "Number of days to check",
    },
  },
});
