import * as React from "react";
import InformationModalDialog from "../../../components/others/modal/InformationModalDialog";
import LeadsFiltersForm from "../form/LeadsFiltersForm";
import moment from "moment";
import ProtectedComponent from "../../../components/authorizations/protectedComponents/ProtectedComponent";
import ProtectedModal from "../../../components/authorizations/protectedComponents/ProtectedModal";
import styles from "./styles/LeadsContent.module.scss";
import { Modal, Progress } from "antd";
import { Button, Icon, Tooltip } from "antd";
import { checkStatus } from "../../../services/m6/FocusCheckStatus";
import { ClearBoth } from "../../../components/controls/clearBoth/ClearBoth";
import { FilterIndicator } from "../components/filterIndicator/FilterIndicator";
import { FoundRowsNumber } from "../../../components/common/fountRowsNumber/FountRowsNumber";
import { getCompaniesListD } from "../../../services/m29_users_management/CompaniesService";
import { getDictionary } from "../../../helpers/DisctionariesHelper";
import { getLeadsStream, validateLeadById } from "../../../services/m56_leads/LeadsService";
import { getListFromLibrary } from "../../../services/m40_libraries/LibrariesService";
import { getPartnersByIds } from "../../../services/m29_users_management/GeneralUserService";
import { getPayoutScheme } from "../../../common/dictionaries/FakePayoutScheme";
import { getProductsD } from "../../../services/m30_products/ProductsService";
import { IDictionaryValue } from "../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { ILeadContentState } from "./interfaces/ILeadContentState";
import { ILeadFilters } from "../interfaces/ILeadFilters";
import { ILeadsContent } from "../interfaces/ILeadsContent";
import { ILeadSendMessage, MessageType } from "./interfaces/ILeadSendMessage";
import { LeadsStatisticsComponent } from "../components/leadsStatistics/LeadsStatisticsComponent";
import { LeadsTable } from "../tables/LeadsTable";
import { Left } from "../../../components/controls/left/Left";
import {
  objectToUrlParams,
  urlParamsToObject,
} from "../../../helpers/UrlStringHelpers";
import {
  openAlertNotification,
  openErrorNotification,
} from "../../../helpers/NotificationHelper";
import { RefreshTableButton } from "../../../components/refreshTableButton/RefreshTableButton";
import { resources } from "../../../common/Resources";
import { Right } from "../../../components/controls/right/Right";
import { RouteComponentProps } from "react-router-dom";
import { sendPostback } from "../../../services/m3_postbacks/PostbacksService";
import { sendToCC } from "../../../services/m4/FocusApiService";
import { Status } from "../../../common/interfaces/IStatus";
import { TableContainer } from "../../../components/containers/tableContainer/TableContainer";
import { UserPermissionType } from "../../../models/IAppUserInfoModel";

const STREAM_TRANSMISSIONS_ATEMPTS_LIMIT = 1;
const TIMEZONE_OFFSET = 13;

const URL_SUPPORTED_FILTERS_SCHEMA = {
  timezone: {
    isArray: false,
    type: "int",
  },
  dateFrom: {
    isArray: false,
    type: "date",
  },
  dateTo: {
    isArray: false,
    type: "date",
  },
  id: {
    isArray: false,
    type: "string",
  },
  id_lead_status: {
    isArray: false,
    type: "int",
  },
  country_id: {
    isArray: false,
    type: "int",
  },
  id_product: {
    isArray: false,
    type: "int",
  },
  id_partner: {
    isArray: false,
    type: "int",
    permission: UserPermissionType.SeeLeadsSelectPartnerButton,
  },
  payout_scheme: {
    isArray: false,
    type: "string",
  },
  api_click_id: {
    isArray: false,
    type: "string",
  },
  api_pub_id: {
    isArray: false,
    type: "string",
  },
  client_phone1: {
    isArray: false,
    type: "string",
  },
  cc_classifiers_name: {
    isArray: false,
    type: "string",
  },
  traffic_source: {
    isArray: false,
    type: "int",
    permission: UserPermissionType.SeeLeadsSelectRemarketingCampaignButton,
  },
  is_contacted: {
    isArray: false,
    type: "string",
    permission: UserPermissionType.SeeContactedStatus,
  },
  is_qualified: {
    isArray: false,
    type: "string",
    permission: UserPermissionType.SeeQualifiedStatus,
  },
  to_check: {
    isArray: false,
    type: "string",
  },
};

export class LeadsContent extends React.Component<
  RouteComponentProps & { isAdmin: boolean },
  ILeadContentState
> {
  _isMounted = false;

  state = {
    leads: Array<ILeadsContent>(),
    partnersD: Array<IDictionaryValue>(),
    productsD: Array<IDictionaryValue>(),
    ccClassifiersD: Array<IDictionaryValue>(),
    ccClassifiersDPureName: Array<IDictionaryValue>(),
    ccCommentsD: Array<IDictionaryValue>(),
    statusesD: Array<IDictionaryValue>(),
    countriesNameD: Array<IDictionaryValue>(),
    payoutSchemeD: Array<IDictionaryValue>(),
    companiesD: Array<IDictionaryValue>(),
    remarketingCampaignsD: Array<IDictionaryValue>(),
    loading: false,
    filters: {} as ILeadFilters,
    filters_list: [] as Array<number>,
    showModal: false,
    success: 0,
    errors: [] as Array<ILeadSendMessage>,
    warnings: [] as Array<ILeadSendMessage>,
    all: 0,
  };

  wasStopped = false;
  failedTransmissionsCounter = 0;
  bufferedLeads = [] as Array<ILeadsContent>;

  componentDidMount = () => {
    this._isMounted = true;

    let filtersFromUrl = urlParamsToObject(
      this.props.location.search,
      URL_SUPPORTED_FILTERS_SCHEMA
    ) as ILeadFilters;

    filtersFromUrl = {
      ...filtersFromUrl,
      timezone: filtersFromUrl.timezone ? filtersFromUrl.timezone : 20,
      dateFrom: filtersFromUrl.dateFrom
        ? filtersFromUrl.dateFrom
        : moment().startOf("day"),
      dateTo: filtersFromUrl.dateTo
        ? filtersFromUrl.dateTo
        : moment().endOf("day"),
    };

    const urlSearch = objectToUrlParams(
      filtersFromUrl,
      URL_SUPPORTED_FILTERS_SCHEMA
    );
    this.props.history.replace({
      search: urlSearch,
    });

    this.setState(
      {
        leads: [],
        filters: filtersFromUrl,
      },
      () => this.getLeadsList()
    );

    this.getCcClassifiersD();
    this.getProductsD();
    this.getStatusesD();
    this.getPayoutSchemeD();
    this.getRemarketingCampaigns();
    this.getCountriesNameD();
    this.getCompaniesD();
  };

  componentWillUnmount = () => (this._isMounted = false);

  public render() {
    const classifiersListWithOrderCreatedStatus: number[] =
      this.getCcClassifiersListWithOrdersCreatedStatus();
    const classifiersListWithNoMoneyStatus: number[] =
      this.getCcClassifiersListWithNoMoneyStatus();
    const { leads, loading, filters } = this.state;

    const { length: countLeadsStatusUnrecognized } = leads
      ? leads.filter((items) => items.id_lead_status === Status.Unrecognized)
      : [];
    const { length: countLeadsStatusAwaiting } = leads
      ? leads.filter((items) => items.id_lead_status === Status.Awaiting)
      : [];
    const { length: countLeadsStatusRejected } = leads
      ? leads.filter((items) => items.id_lead_status === Status.Rejected)
      : [];
    const { length: countLeadsStatusSold } = leads
      ? leads.filter((items) => items.id_lead_status === Status.Sold)
      : [];
    const { length: countLeadsStatusTrash } = leads
      ? leads.filter((items) => items.id_lead_status === Status.Trash)
      : [];
      const { length: countLeadsStatusCheck } = leads
      ? leads.filter((items) => Boolean(items.to_check))
      : [];
    const { length: countLeadsStatusExcluded } = leads
      ? leads.filter((items) => items.id_lead_status === Status.Excluded)
      : [];
    const { length: countLeadsStatusValidated } = leads
      ? leads.filter((items) => items.id_lead_status === Status.Validated)
      : [];
    const { length: countLeadsStatusSendToCc } = leads
      ? leads.filter((items) => items.id_lead_status === Status.SendToCc)
      : [];
    const { length: countLeadsStatusDoubled } = leads
      ? leads.filter((items) => items.id_lead_status === Status.Double)
      : [];
    const { length: countLeadsStatusNewLead } = leads
      ? leads.filter((items) => items.id_lead_status === Status.NewLead)
      : [];
    const { length: orderCreated } = leads
      ? leads.filter((items) =>
        classifiersListWithOrderCreatedStatus.includes(
            items.cc_classifiers_id!
        )
      )
      : [];
    const countLeadsTotal =
      countLeadsStatusAwaiting +
      countLeadsStatusRejected +
      countLeadsStatusSold +
      countLeadsStatusExcluded +
      countLeadsStatusValidated +
      countLeadsStatusSendToCc;
    const approvedRate = (countLeadsStatusSold / countLeadsTotal) * 100;
    const verifiedApprovrdRate =
      ((orderCreated + countLeadsStatusSold) / countLeadsTotal) * 100;
    const countLeadsAwaitingAll =
      countLeadsStatusNewLead +
      countLeadsStatusValidated +
      countLeadsStatusAwaiting +
      countLeadsStatusSendToCc;
    const { length: countNoMoney } = leads
      ? leads.filter((items) =>
        classifiersListWithNoMoneyStatus.includes(items.cc_classifiers_id!)
      )
      : [];

    const filteredData: ILeadsContent[] = this.filterList(
      leads,
      classifiersListWithOrderCreatedStatus
    );

    const { length: count } = filteredData;

    const { success, errors, all } = this.state;
    const completed = errors.length + success;
    const errorsCount = errors.reduce(
      (acc: number, error: ILeadSendMessage) => {
        return acc + error.type === MessageType.ERROR ? 1 : 0;
      },
      0
    );
    const warningsCount = errors.length - errorsCount;
    const booleanSelect = [
      {
        id: "false",
        name: resources.buttons.no,
      },
      {
        id: "true",
        name: resources.buttons.yes,
      },
    ];

    return (
      <div>
        <div className={styles.affix_styles}>
          <Left>
            <RefreshTableButton
              className={styles.refresh_table}
              loading={this.state.loading}
              onClick={this.refreshTable}
            />
          </Left>
          <Left>
            <ProtectedModal
              permission={UserPermissionType.SeeLeadsFiltersModalButton}
            >
              <InformationModalDialog
                modalTitle={`${resources.title.filters}`}
                buttonType="primary"
                buttonTitle={resources.buttons.filter}
                buttonTooltip={resources.modalTooltips.filters}
                icon="sliders"
                withHideMethod={true}
                width={"80%"}
                buttonClassName={styles.filters_button}
                disabled={this.state.loading}
              >
                <LeadsFiltersForm
                  {...filters}
                  booleanSelect={booleanSelect}
                  date={[filters.dateFrom, filters.dateTo]}
                  productsD={this.state.productsD}
                  partnersD={this.state.partnersD}
                  statusesD={this.state.statusesD}
                  companiesD={this.state.companiesD}
                  countriesNameD={this.state.countriesNameD}
                  payoutSchemaD={this.state.payoutSchemeD}
                  ccClassifiersD={this.state.ccClassifiersDPureName}
                  remarketingCampaignsD={this.state.remarketingCampaignsD}
                  setFilters={this.setFilters}
                  getLeads={this.getLeadsList}
                />
              </InformationModalDialog>
            </ProtectedModal>
          </Left>

          {this.props.isAdmin && (
            <Left>
              <span
                style={{
                  display: "block",
                  marginTop: "11px",
                  marginLeft: "10px",
                  color: "#000000d9",
                }}
              >
                To see <b>Remarketing</b> leads please select P2 in “Remarketing
                campaign” filter. To see PROGIZI leads select it in the filter.
              </span>
            </Left>
          )}

          <Right>
            <FoundRowsNumber
              className={styles.found_rows_number}
              loading={loading}
              count={count}
            />
          </Right>
        </div>
        <ClearBoth />
        <TableContainer className={styles.leads_container_style}>
          <ProtectedComponent
            permission={UserPermissionType.SeeLeadsFiltersModalButton}
          >
            <FilterIndicator
              filters={this.state.filters}
              booleanSelect={booleanSelect}
              productsD={this.state.productsD}
              partnersD={this.state.partnersD}
              statusesD={this.state.statusesD}
              countriesNameD={this.state.countriesNameD}
              payoutSchemaD={this.state.payoutSchemeD}
              ccClassifiersD={this.state.ccClassifiersD}
              companiesD={this.state.companiesD}
              remarketingCampaignsD={this.state.remarketingCampaignsD}
            />
          </ProtectedComponent>
          <ProtectedComponent permission={UserPermissionType.SeeLeadStatistics}>
            <LeadsStatisticsComponent
              countLeadsStatusUnrecognized={countLeadsStatusUnrecognized}
              countLeadsTotal={countLeadsTotal}
              countLeadsStatusAwaiting={countLeadsStatusAwaiting}
              countLeadsStatusRejected={countLeadsStatusRejected}
              countLeadsStatusSold={countLeadsStatusSold}
              countLeadsStatusTrash={countLeadsStatusTrash}
              countLeadsStatusExcluded={countLeadsStatusExcluded}
              countLeadsStatusValidated={countLeadsStatusValidated}
              countLeadsStatusSendToCc={countLeadsStatusSendToCc}
              countLeadsStatusDoubled={countLeadsStatusDoubled}
              verifiedApprovrdRate={verifiedApprovrdRate}
              approvedRate={approvedRate}
              orderCreated={orderCreated}
              countNoMoney={countNoMoney}
              countLeadsAwaitingAll={countLeadsAwaitingAll}
              countLeadsStatusCheck={countLeadsStatusCheck}
              loading={loading}
              //TODO: Filtrowanie po statystykach
              // handleChageFilter={this.handleChageFilter}
            />
          </ProtectedComponent>
          <ClearBoth />
          <div>
            <LeadsTable
              displayStatusName
              loading={loading}
              data={loading ? [] : filteredData}
              tableName={"LeadsTable"}
              partnersD={this.state.partnersD}
              productsD={this.state.productsD}
              ccClassifiersD={this.state.ccClassifiersD}
              ccCommentsD={this.state.ccCommentsD}
              leadActions={this.leadActions}
              timezone={this.state.filters.timezone}
            />
          </div>
        </TableContainer>
        <Modal
          visible={this.state.showModal}
          footer={null}
          closable={true}
          onCancel={() => {
            this.wasStopped = true;
            this._isMounted && this.setState({ showModal: false });
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Progress
              type="circle"
              format={() => success + errors.length + " / " + all}
              percent={(completed / all) * 100}
              successPercent={(success / all) * 100}
            />
            <div>
              {resources.labels.success}: {success}
            </div>
            <div>
              {resources.labels.fails}: {errorsCount}
            </div>
            <div>
              {resources.labels.warnings}: {warningsCount}
            </div>
            {errors.length > 0 && (
              <div className={styles.errorsContainer}>
                <div className={styles.headerRow}>
                  <div className={styles.leadIdContainer}>Lead ID</div>
                  <div className={styles.messageContainer}>
                    {resources.labels.message}
                  </div>
                </div>
                {errors.map((error: ILeadSendMessage, idx) => (
                  <div
                    className={
                      error.type === MessageType.ERROR
                        ? styles.errorRow
                        : styles.warningRow
                    }
                    key={idx}
                  >
                    <div className={styles.leadIdContainer}>{error.leadId}</div>
                    <div className={styles.messageContainer}>
                      {error.message}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  onLeadReceived = (data: any) => {
    let newLead = { ...data } as ILeadsContent;

    var myObj = JSON.parse(data.api_params_json);

    newLead.lp_url = myObj["lp_url"];
    newLead.client_ip = myObj["client_ip"];

    this.bufferedLeads.push(newLead);

    if (this.bufferedLeads.length === 500) {
      this._isMounted &&
        this.setState((prevState) => {
          return {
            ...prevState,
            leads: [...prevState.leads, ...this.bufferedLeads],
          };
        });
      this.bufferedLeads = [];
    }
  };

  onTransmissionCompleted = () => {
    if (this.bufferedLeads.length > 0) {
      this._isMounted &&
        this.setState((prevState) => {
          const oldLeads = prevState.leads;
          oldLeads.push(...this.bufferedLeads);
          return {
            ...prevState,
            leads: oldLeads,
            loading: false,
          };
        }, this.getPartnersD);
      this.bufferedLeads = [];
    } else {
      this.getPartnersD();
    }
    this._isMounted &&
      this.setState(
        {
          loading: false,
        },
        () => window.scrollTo(0, 0)
      );
  };

  onTransmissionError = (errMsg: string) => {
    this.failedTransmissionsCounter = this.failedTransmissionsCounter + 1;
    this._isMounted &&
      this.setState(
        {
          leads: [],
        },
        () => {
          if (
            this.failedTransmissionsCounter < STREAM_TRANSMISSIONS_ATEMPTS_LIMIT
          ) {
            this.getLeadsList();
          } else {
            this._isMounted &&
              this.setState({
                loading: false,
              });
            openErrorNotification("Failed to fetch leads data");
          }
        }
      );
  };

  getLeadsList = async () => {
    this._isMounted && this.setState({ loading: true, leads: [] });

    const urlSearch = objectToUrlParams(
      this.state.filters,
      URL_SUPPORTED_FILTERS_SCHEMA
    );
    this.props.history.replace({
      search: urlSearch,
    });

    const filterFromState = { ...this.state.filters };
    const timezoneOffset = this.state.filters.timezone
      ? TIMEZONE_OFFSET - this.state.filters.timezone
      : 0;

    if (filterFromState.payout_scheme) {
      const selectedSchema = this.state.payoutSchemeD.find(
        (el: IDictionaryValue) => el.id === filterFromState.payout_scheme
      );
      if (selectedSchema) {
        filterFromState.payout_scheme = selectedSchema.value;
      }
    }

    if (Number(filterFromState.traffic_source) === 99) {
      filterFromState.traffic_source = [1, 5];
    } else if (Number(filterFromState.traffic_source) === 100) {
      filterFromState.traffic_source = ["null"];
    } else if (filterFromState.traffic_source) {
      filterFromState.traffic_source = [filterFromState.traffic_source];
    } else {
      filterFromState.traffic_source = ["null", 1, 5];
    }

    const filters = {
      ...filterFromState,
      traffic_source: [...filterFromState.traffic_source],
      id: filterFromState.id ? Number(filterFromState.id) : undefined,
      op: "",
      cl: "",
      sep: "\n\n",
      dateFrom: moment(filterFromState.dateFrom)
        .add(timezoneOffset, "hours")
        .format("YYYY/MM/DD HH:mm:ss")
        .toString(),
      dateTo: moment(filterFromState.dateTo)
        .add(timezoneOffset, "hours")
        .format("YYYY/MM/DD HH:mm:ss")
        .toString(),
      columns: [
        "id",
        "id_lead_status",
        "cc_classifiers_id",
        "id_product",
        "country_id",
        "id_partner",
        "payout_actual_local_usd",
        "payout_scheme",
        "client_phone1_original",
        "client_phone1",
        "api_click_id",
        "api_pub_id",
        "created_at",
        "date_approved_at",
        "cc_classifiers_id_end",
        "api_params_json",
        "is_contacted",
        "is_qualified",
        "to_check",
      ],
    };

    getLeadsStream(
      filters,
      this.onLeadReceived,
      this.onTransmissionCompleted,
      this.onTransmissionError
    );
  };

  refreshTable = () => {
    this.getLeadsList();
  };

  getPartnersD = async () => {
    const { leads } = this.state;
    const partnerIdsSet = new Set();
    for (let lead of leads) {
      partnerIdsSet.add(lead.id_partner);
    }
    if (this.state.filters.id_partner) {
      partnerIdsSet.add(this.state.filters.id_partner);
    }
    await getPartnersByIds(Array.from(partnerIdsSet) as Array<number>).then(
      (response) => {
        const partnersD = response
          ? response.sort((a: any, b: any) =>
            a.id > b.id ? 1 : b.id > a.id ? -1 : 0
          )
          : [];
        this._isMounted && this.setState({ partnersD });
      }
    );
  };

  getProductsD = async () => {
    await getProductsD()
      .then(productsD => productsD.sort((a: any, b: any) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")))
      .then(
        (productsD) => this._isMounted && this.setState({ productsD })
      );
  };

  getStatusesD = () => {
    this.setState({ statusesD: getDictionary("statusesD") });
  };

  getCountriesNameD = () => {
    this.setState({ countriesNameD: getDictionary("countriesNameD") });
  };

  getCompaniesD = () => {
    getCompaniesListD().then(
      (companiesD) => this._isMounted && this.setState({ companiesD })
    );
  };

  getCcClassifiersD = async () => {
    await getListFromLibrary({
      name: "cc_classifiers",
      column: "id, name, postback_comment",
    }).then((response) => {
      if (this._isMounted) {
        const ccClassifiersD = response.map((item: any) => {
          return {
            id: item.id,
            name: `${item.name} [${item.id}]`,
            value: item.name,
          };
        });
        const ccClassifiersDPureName = response.map((item: any) => {
          return { id: item.id, name: item.name, value: item.name };
        });
        const ccCommentsD = response.map((item: any) => {
          return { id: item.id, name: item.postback_comment };
        });
        this.setState({
          ccClassifiersD,
          ccClassifiersDPureName,
          ccCommentsD,
        });
      }
    });
  };

  getPayoutSchemeD = () => {
    this.setState({ payoutSchemeD: getPayoutScheme() });
  };

  getRemarketingCampaigns = () => {
    getListFromLibrary({
      name: "remarketing_campaigns",
      column: "id, name",
    }).then((response) => {
      let remarketingCampaignsD = [
        ...response.map((item: any) => {
          return { id: item.id, name: `${item.name} [${item.id}]` };
        }),
        { id: 99, name: "Long page - total [ 1, 5 ]" },
        { id: 100, name: "Lead source [ null ]" },
      ];
      remarketingCampaignsD = remarketingCampaignsD.sort((a, b) =>
        (a.name ? a.name : "").localeCompare(b.name ? b.name : "")
      );
      this._isMounted && this.setState({ remarketingCampaignsD });
    });
  };

  getCcClassifiersListWithOrdersCreatedStatus = () => {
    let list: number[] = [];

    this.state.ccClassifiersD.map((item) => {
      if (item.value === ".ORDER CREATED" || item.value === "ORDER CREATED")
        list = [...list, Number(item.id)];

      return null;
    });

    return list;
  };

  getCcClassifiersListWithNoMoneyStatus = () => {
    let list: number[] = [];

    this.state.ccClassifiersD.map((item) => {
      if (item.value === ".NO MONEY" || item.value === "NO MONEY")
        list = [...list, Number(item.id)];

      return null;
    });

    return list;
  };

  filterList = (
    leads: ILeadsContent[],
    classifiersListWithOrderCreatedStatus: number[]
  ): ILeadsContent[] => {
    let filteredData: ILeadsContent[] = [];
    let filters_list = this.state.filters_list;

    if (filters_list.length > 0) {
      if (filters_list.includes(Status.OrderCreated)) {
        filters_list = filters_list.filter((x) => x !== Status.OrderCreated);
        filteredData = leads.filter((items) =>
          classifiersListWithOrderCreatedStatus.includes(
            items.cc_classifiers_id
          )
        );
      }

      filteredData = [
        ...filteredData,
        ...leads.filter((items) => filters_list.includes(items.id_lead_status)),
      ];
    } else {
      filteredData = leads;
    }

    return filteredData;
  };

  pushFilterToList = (id: number) => {
    const filters_list: number[] = this.state.filters_list;

    filters_list.push(id);

    this._isMounted && this.setState({ filters_list });
  };

  removeFilterFromList = (id: number) => {
    const filters_list = this.state.filters_list.filter((x) => x !== id);

    this._isMounted && this.setState({ filters_list });
  };

  handleChageFilter = (id: number) => {
    this.state.filters_list.length > 0 && this.state.filters_list.includes(id)
      ? this.removeFilterFromList(id)
      : this.pushFilterToList(id);
  };

  displayFilters = () => {
    return (
      this.state.filters_list.length > 0 && (
        <>
          {this.state.filters_list.map((id) => {
            return (
              <Tooltip
                title={id !== 100 ? "Status filter" : "Classifier filter"}
              >
                <Button
                  key={id}
                  className={
                    id !== 100
                      ? styles.filterButton
                      : styles.filterButtonOrderCreated
                  }
                  onClick={() => this.handleChageFilter(id)}
                >
                  {this.displayStatusName(id)}
                  <Icon type="close" />
                </Button>
              </Tooltip>
            );
          })}
        </>
      )
    );
  };

  displayStatusName = (id: number) => {
    switch (id) {
    case Status.Awaiting:
      return "Awaiting";
    case Status.Rejected:
      return "Rejected";
    case Status.Unrecognized:
      return "Unrecognized";
    case Status.Sold:
      return "Sold";
    case Status.OrderCreated:
      return "Order created";
    case Status.Trash:
      return "Trash";
    case Status.Excluded:
      return "Excluded";
    case Status.Validated:
      return "Validated";
    case Status.SendToCc:
      return "Send to CC";
    case Status.Double:
      return "Double";
    default:
      return "-";
    }
  };

  setFilters = (filters: ILeadFilters) => {
    this._isMounted && this.setState({ filters });
  };

  sendPostback = async (leadsIds: Array<number>) => {
    this.wasStopped = false;
    this._isMounted &&
      this.setState({
        success: 0,
        errors: [] as Array<ILeadSendMessage>,
        all: leadsIds.length,
        showModal: true,
      });
    return new Promise(async (resolve, reject) => {
      for (const leadId of leadsIds) {
        if (this.wasStopped) {
          openAlertNotification("Sending postbacks canceled!");
          break;
        }
        try {
          await sendPostback(leadId);
          this.setState((prevState) => ({ success: prevState.success + 1 }));
        } catch (e) {
          const { status, data } = e.response;

          this.setState((prevState) => {
            const type =
              status === 409 ? MessageType.WARNING : MessageType.ERROR;
            const errors = [
              ...prevState.errors,
              { leadId, message: data.message, type },
            ];
            return {
              ...prevState,
              errors,
            };
          });
        }
      }
      resolve();
    });
  };

  sendToCallCenter = async (leadsIds: Array<number>) => {
    this.wasStopped = false;
    this._isMounted &&
      this.setState({
        success: 0,
        errors: [] as Array<ILeadSendMessage>,
        all: leadsIds.length,
        showModal: true,
      });
    return new Promise(async (resolve, reject) => {
      const successfulIds = [];
      for (const leadId of leadsIds) {
        if (this.wasStopped) {
          openAlertNotification("Sending to call center canceled!");
          break;
        }
        try {
          await sendToCC(leadId);
          successfulIds.push(leadId);
          this._isMounted &&
            this.setState((prevState) => ({
              success: prevState.success + 1,
            }));
        } catch (e) {
          const { data } = e.response;

          this.setState((prevState) => {
            const errors = [
              ...prevState.errors,
              { leadId, message: data.message, type: MessageType.ERROR },
            ];
            return {
              ...prevState,
              errors,
            };
          });
        }
      }
      this.updateLeads(successfulIds);
      resolve();
    });
  };

  checkStatus = async (leadsIds: Array<number>) => {
    this.wasStopped = false;
    this._isMounted &&
      this.setState({
        success: 0,
        errors: [] as Array<ILeadSendMessage>,
        all: leadsIds.length,
        showModal: true,
      });
    return new Promise(async (resolve, reject) => {
      const successfulIds = [];
      for (const leadId of leadsIds) {
        if (this.wasStopped) {
          openAlertNotification("Checking statuses canceled!");
          break;
        }
        try {
          await checkStatus(leadId);
          successfulIds.push(leadId);
          this._isMounted &&
            this.setState((prevState) => ({
              success: prevState.success + 1,
            }));
        } catch (e) {
          const { data } = e.response;

          this.setState((prevState) => {
            const errors = [
              ...prevState.errors,
              { leadId, message: data.message, type: MessageType.ERROR },
            ];
            return {
              ...prevState,
              errors,
            };
          });
        }
      }
      this.updateLeads(successfulIds);
      resolve();
    });
  };

  validate = async (leadsIds: Array<number>) => {
    this.wasStopped = false;
    this._isMounted &&
      this.setState({
        success: 0,
        errors: [] as Array<ILeadSendMessage>,
        all: leadsIds.length,
        showModal: true,
      });
    return new Promise(async (resolve, reject) => {
      const successfulIds = [];
      for (const leadId of leadsIds) {
        if (this.wasStopped) {
          openAlertNotification("Checking statuses canceled!");
          break;
        }
        try {
          await validateLeadById(leadId);
          successfulIds.push(leadId);
          this._isMounted &&
            this.setState((prevState) => ({
              success: prevState.success + 1,
            }));
        } catch (e) {
          const { data } = e.response;

          this.setState((prevState) => {
            const errors = [
              ...prevState.errors,
              { leadId, message: data.message, type: MessageType.ERROR },
            ];
            return {
              ...prevState,
              errors,
            };
          });
        }
      }
      this.updateLeads(successfulIds);
      resolve();
    });
  };

  updateLeads = (leadsIds: Array<number>) => {
    this._isMounted && this.setState({ loading: true });

    const filters = {
      id: leadsIds,
      op: "",
      cl: "",
      sep: "\n\n",
    };

    const updatedLeads = Array<ILeadsContent>();

    const onLeadReceived = (data: ILeadsContent) => {
      updatedLeads.push(data);
    };

    const onTransmissionCompleted = () => {
      const leadsToUpdate = this.state.leads;
      for (const updatedLead of updatedLeads) {
        const leadToUpdateIdx = leadsToUpdate.findIndex(
          (lead: ILeadsContent) => lead.id === updatedLead.id
        );
        leadsToUpdate[leadToUpdateIdx] = updatedLead;
      }
      this._isMounted &&
        this.setState({ loading: false, leads: leadsToUpdate });
    };

    getLeadsStream(
      filters,
      onLeadReceived,
      onTransmissionCompleted,
      this.onTransmissionError
    );
  };

  leadActions = [
    {
      name: "Send postback",
      key: 1,
      callback: this.sendPostback,
      permission: UserPermissionType.LeadsSendPostback,
    },
    {
      name: "Send to call center",
      key: 2,
      callback: this.sendToCallCenter,
      permission: UserPermissionType.LeadsSendToCallCenter,
    },
    {
      name: "Check status in call center",
      key: 3,
      callback: this.checkStatus,
      permission: UserPermissionType.LeadsCheckStatusInCallCenter,
    },
    {
      name: "Validate",
      key: 4,
      callback: this.validate,
      permission: UserPermissionType.LeadsCheckStatusInCallCenter,
    },
  ];
}
